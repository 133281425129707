import moment from 'moment'

function groupedDays(messages) {
  return messages.reduce((acc, el) => {
    const messageDay = moment(el.created_at).format('YYYY-MM-DD')
    if (acc[messageDay]) {
      acc[messageDay].push(el)
    } else {
      acc[messageDay] = [el]
    }
    return acc
  }, {})
}

function generateItems(messages) {
  const days = groupedDays(messages)

  const sortedDays = Object.keys(days).sort(
    (x, y) => moment(y, 'YYYY-MM-DD').unix() - moment(x, 'YYYY-MM-DD').unix()
  )

  const items = sortedDays.reduce((acc, date) => {
    const sortedMessages = days[date].sort(
      (a, b) => parseInt(b.msg_id) - parseInt(a.msg_id)
    )
    return acc.concat([...sortedMessages, {type: 'day', date, id: date}])
  }, [])

  return items
}

export default generateItems

