import { combineReducers } from "redux";
import bodyStylingReducer from "./slices/bodyStyling";
import loginReducer from "./slices/loginSlice";
import watchSlice from "./slices/watchSlice";
import givingSlice from "./slices/givingSlice";
import connectSlice from "./slices/connectSlice";
import notificationSlice from "./slices/notificationSlice";

const rootReducer = combineReducers({
    bodyStyling: bodyStylingReducer,
    login: loginReducer,
    watch: watchSlice,
    giving: givingSlice,
    connect: connectSlice,
    notification: notificationSlice,
});

export { rootReducer };
