import React, { useState, useCallback, useRef, useEffect } from "react";
import { Circles } from "react-loader-spinner";
import { removeStartingSlash } from "../../utills/RemoveSlash";
import placeholder from "../../assets/placeholder.png";
import InfiniteScroll from "react-infinite-scroller";
import XHR from "../../utills/XHR";
import { useSelector } from "react-redux";
import _ from "lodash";
import {
    MessageCircleMore,
    Minimize,
    Minimize2,
    Paperclip,
    Search,
    Send,
    X,
} from "lucide-react";
import ChatBubble from "./ChatBubble";
import moment from "moment";
import generateItems from "../../utills/GenerateItems";
import InfiniteScrollComp from "react-infinite-scroll-component";
import { database } from "../../firebase/firebaseConfig.js";
import { ref, onChildAdded, off, onValue } from "firebase/database";
import ChatOverayLoader from "../../components/Loader/ChatOverlayLoader.js";
import toast from "react-hot-toast";

const ChatHeadComp = () => {
    const user = useSelector((state) => state.login.userData);
    const [loader, setLoader] = useState({
        connections: false,
        chat: false,
        editMsg: false,
        overlay: null,
    });
    const [showConnections, setShowConnections] = useState(false);
    const [connections, setConnections] = useState([]);
    const hostname = process.env.REACT_APP_BASE_URL;
    const [filteredConnections, setFilteredConnections] = useState([]);
    const [connectionSearchQuery, setConnectionSearchQuery] = useState("");
    const [showChat, setShowChat] = useState(false);
    const [chatData, setChatData] = useState([]);
    const [visibleReactions, setVisibleReactions] = useState(false);
    const [editMsg, setEditMsg] = useState({ msg: "", id: "" });
    const [selectedImg, setSelectedImg] = useState(null);
    const [message, setMessage] = useState("");

    const inputRefs = useRef({}); // Create a ref object to store input values

    const handleInputChange = (id, value) => {
        // Update the value for the specific input
        if (inputRefs.current[id]) {
            inputRefs.current[id].value = value; // Update the ref's value
            // console.log(`Input ${id} value:`, value);
        }
    };

    const handleClearInput = (id) => {
        // Clear the value for the specific input
        if (inputRefs.current[id]) {
            inputRefs.current[id].value = ""; // Clear the input via the ref
        }
    };

    const fileInputRefs = useRef({});

    const handleButtonClick = (id) => {
        // Trigger the file input
        if (fileInputRefs.current[id]) {
            fileInputRefs.current[id]?.click();
        }
    };

    const handleSetEditMsg = (msg, convo) => {
        setChatData((prev) => {
            const chatObjIndex = prev.findIndex(
                (item) => item?.conversation_id == convo?.conversation_id
            );
            if (chatObjIndex !== -1) {
                const tempData = [...prev];
                tempData[chatObjIndex] = {
                    ...tempData[chatObjIndex],
                    editMsgObj: msg,
                };
                handleInputChange(convo?.user_id, msg !== null ? msg?.msg : "");
                return tempData;
            }
            return prev;
        });
    };

    const handleFileChange = (e, chatUserId) => {
        // Handle the selected file
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            if (selectedFile.type.startsWith("image/")) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    console.log("IMG>", event.target.result);
                    // setSelectedImg(event.target.result);
                    setChatData((prev) => {
                        const temp = [...prev];
                        const objIndex = temp.findIndex(
                            (item) => item?.user_id == chatUserId
                        );
                        if (objIndex !== -1) {
                            temp[objIndex] = {
                                ...temp[objIndex],
                                selectedImg: event.target.result,
                            };
                            return temp;
                        }
                        return prev;
                    });
                };
                reader.readAsDataURL(selectedFile);
            } else {
                alert("Please select a valid image file.");
            }
        }
        // Reset the file input's value to allow selecting the same file again
        e.target.value = null;
    };

    const getConnections = () => {
        if (loader.connections) return;
        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.api_token}`,
            },
            method: "GET",
        };
        setLoader((prev) => {
            return {
                ...prev,
                connections: true,
            };
        });
        XHR(
            `${hostname}/api/v1/getFamilyAndConnections?user_id=${user?.id}`,
            options
        )
            .then((resp) => {
                if (resp?.data?.family_and_connections) {
                    setConnections(resp.data.family_and_connections);
                    // console.log(resp.data);
                }
            })
            .catch((e) => {
                console.log("ERR>", e);
            })
            .finally(() => {
                setLoader((prev) => {
                    return {
                        ...prev,
                        connections: false,
                    };
                });
            });
    };

    const getChat = (id, page) => {
        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.api_token}`,
            },
            method: "POST",
            data: {
                user_id: id ? id : null,
                conversation_id: 0,
                page,
            },
        };
        setLoader((prev) => {
            return {
                ...prev,
                chat: true,
                overlay: page == 1 ? id : null,
            };
        });

        XHR(`${hostname}/api/v1/getChat?page=${page}`, options)
            .then((resp) => {
                if (resp?.data) {
                    const {
                        chat,
                        chat_current_page,
                        chat_last_page,
                        conversation_id,
                    } = resp.data;

                    if (page == 1) {
                        setChatData((prev) => {
                            const userObj = prev.find(
                                (el) => el?.user_id == id
                            );
                            const userObjIndex = prev.findIndex(
                                (el) => el?.user_id == id
                            );
                            if (userObj) {
                                const data = {
                                    ...userObj,
                                    chat,
                                    chat_current_page,
                                    chat_last_page,
                                    conversation_id,
                                };

                                const temp = [...prev];
                                temp[userObjIndex] = {
                                    ...temp[userObjIndex],
                                    ...data,
                                };
                                return temp;
                            }
                            return prev;
                        });
                    } else {
                        setChatData((prev) => {
                            const userObj = prev.find(
                                (el) => el?.user_id == id
                            );
                            const userObjIndex = prev.findIndex(
                                (el) => el?.user_id == id
                            );
                            if (userObj) {
                                const data = {
                                    ...userObj,
                                    chat: [...userObj?.chat, ...chat],
                                    chat_current_page,
                                    chat_last_page,
                                    conversation_id,
                                };
                                const temp = [...prev];
                                temp[userObjIndex] = data;
                                return temp;
                            }

                            return prev;
                        });
                    }
                }
            })
            .catch((e) => {
                console.log("ERR>", e);
            })
            .finally(() => {
                setLoader((prev) => {
                    return {
                        ...prev,
                        chat: false,
                        overlay: null,
                    };
                });
            });
    };

    const NewChat = () => {
        return (
            <div
                onClick={() => {
                    if (chatData?.length < 3) {
                        setShowConnections(!showConnections);
                        if (connections.length == 0) {
                            getConnections();
                        }
                    } else {
                        toast.error(
                            "Maximum of 3 chat heads allowed. Close a previous chat to continue.",
                            {
                                style: { backgroundColor: "#fff" },
                                icon: "⚠️",
                                id: "max 3 alert",
                                duration: 4000,
                            }
                        );
                    }
                }}
                className="chat-head-btn"
            >
                <MessageCircleMore size={30} color="#fff" />
            </div>
        );
    };

    const debouncedConnectionSetMessage = useCallback(
        _.debounce((text) => {
            setConnectionSearchQuery(text);
            if (connections.length > 0) {
                filterConnections(text);
            }
        }, 700),
        [connections]
    );

    const filterConnections = useCallback(
        (text) => {
            const filtered = connections?.filter((item) => {
                return item?.username
                    ?.toLowerCase()
                    ?.includes(text.toLowerCase());
            });

            if (filtered.length > 0) {
                const removedDuplicates = filtered.filter(
                    (item, index, self) =>
                        index === self.findIndex((t) => t.id === item.id) // Use the id or another unique property
                );
                removedDuplicates && setFilteredConnections(removedDuplicates);
            } else {
                setFilteredConnections([]);
            }
        },
        [connections]
    );

    const onEndChat = (userid) => {
        const userObj = chatData?.find((item) => item.user_id == userid);

        if (userObj) {
            if (
                userObj.chat_current_page < userObj.chat_last_page
                //  &&
                // !loader.chat
            ) {
                const nextPage = userObj.chat_current_page + 1;
                getChat(userid, nextPage);
            }
        }
    };

    const ConnectionList = useCallback(
        ({
            connections,
            connectionSearchQuery,
            filteredConnections,
            loader,
            debouncedConnectionSetMessage,
            getChat,
            setShowChat,
            setChatData,
            chatData,
        }) => {
            const avatarStyle = {
                width: "38px",
                height: "38px",
                fontSize: "1.2rem",
            };
            return (
                <div
                    className="d-flex flex-column"
                    style={{
                        width: 320,
                        borderRadius: 10,
                        // padding: 10,
                        backgroundColor: "#171717",
                        filter: "drop-shadow(2px 4px 6px black)",
                    }}
                >
                    <div
                        className="d-flex align-items-center justify-content-between"
                        style={{
                            // borderBottom: "1px solid #fff",
                            padding: 10,
                        }}
                    >
                        <h5
                            className=""
                            style={{ color: "#fff", fontSize: 15 }}
                        >
                            New Chat
                        </h5>
                        <X
                            onClick={() => setShowConnections(false)}
                            size={24}
                            color="#fff"
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                    <div
                        className="px-2 pb-2"
                        style={{ borderBottom: "1px solid #4f4c4c" }}
                    >
                        <div
                            className="position-relative"
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            <Search
                                className="text-secondary"
                                // style={{ left: 10 }}
                                color="#fff"
                            />
                            <input
                                type="text"
                                onChange={(e) =>
                                    debouncedConnectionSetMessage(
                                        e.target.value
                                    )
                                }
                                placeholder="Search..."
                                className="ps-2"
                                style={{
                                    backgroundColor: "transparent",
                                    outline: "unset",
                                    border: "unset",
                                    color: "#fff",
                                    padding: 6,
                                    width: "100%",
                                }}
                            />
                        </div>
                    </div>
                    <div
                        className="convo-cont"
                        style={{
                            height: "calc(65vh - 84px)",
                            overflowY: "auto",
                            padding: 10,
                        }}
                    >
                        <InfiniteScroll
                            pageStart={0}
                            hasMore={false}
                            useWindow={false}
                            className="d-flex flex-column "
                        >
                            {(connectionSearchQuery.length > 0
                                ? filteredConnections
                                : connections
                            ).map((item, index) => (
                                <div
                                    key={item?.id}
                                    onClick={() => {
                                        setShowConnections(false);
                                        if (chatData && chatData?.length == 3) {
                                            return;
                                        }
                                        const alreadyExist = chatData.find(
                                            (chatObj) =>
                                                chatObj?.user_id == item?.id
                                        );
                                        if (alreadyExist) {
                                            return;
                                        }
                                        setChatData((prev) => {
                                            const data = {
                                                name: item?.username,
                                                image: item?.profile_pic,
                                                chat: [],
                                                user_id: item?.id,
                                                selectedImg: null,
                                                minimized: false,
                                                conversation_id: null,
                                                editMsgObj: null,
                                            };
                                            const temp = [data, ...prev];
                                            return temp;
                                        });
                                        getChat(item?.id, 1);
                                    }}
                                    className="p-2 convo-item"
                                    style={{
                                        // backgroundColor: "#1b1b1b",
                                        borderBottom: "unset",
                                        borderRadius: 4,
                                    }}
                                >
                                    <div
                                        className="rounded-circle bg-primary bg-opacity-10 text-primary d-flex align-items-center justify-content-center flex-shrink-0"
                                        style={avatarStyle}
                                    >
                                        <img
                                            style={{
                                                height: 38,
                                                width: 38,
                                                borderRadius: 50,
                                                objectFit: "cover",
                                            }}
                                            src={
                                                item?.profile_pic
                                                    ? `${hostname}/${removeStartingSlash(
                                                          item.profile_pic
                                                      )}`
                                                    : placeholder
                                            }
                                        />
                                    </div>
                                    <div
                                        className="ms-3 flex-grow-1 min-width-0"
                                        style={{ maxWidth: 220 }}
                                    >
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h6
                                                className="mb-0 text-truncate"
                                                style={{
                                                    color: "#fff",
                                                    fontSize: 14,
                                                }}
                                            >
                                                {item?.username}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {loader.connections && (
                                <div className="d-flex align-items-center justify-content-center py-3">
                                    <Circles
                                        height="30"
                                        width="30"
                                        color="#347bff"
                                        ariaLabel="circles-loading"
                                        wrapperClass=""
                                        visible={true}
                                    />
                                </div>
                            )}
                        </InfiniteScroll>
                    </div>
                </div>
            );
        },
        []
    );

    const RenderItem = useCallback(
        ({
            item,
            hostname,
            placeholder,
            removeStartingSlash,
            user,
            chat,
            delMsg,
            setEditMsg,
            setVisibleReactions,
            visibleReactions,
            handleReactionApi,
        }) => {
            return item?.type === "day" ? (
                <div
                    className="d-flex my-2"
                    style={{
                        alignSelf: "center",
                        backgroundColor: "#656565",
                        paddingBlock: 4,
                        paddingInline: 10,
                        borderRadius: 6,
                    }}
                >
                    <p
                        style={{
                            color: "#d9d9d9",
                            fontWeight: "bold",
                        }}
                    >
                        {moment(item?.date).format("MMMM DD, YYYY")}
                    </p>
                </div>
            ) : (
                <ChatBubble
                    item={item}
                    hostname={hostname}
                    placeholder={placeholder}
                    removeStartingSlash={removeStartingSlash}
                    user={user}
                    chat={chat}
                    delMsg={delMsg}
                    setEditMsg={setEditMsg}
                    setVisibleReactions={setVisibleReactions}
                    visibleReactions={visibleReactions}
                    handleReactionApi={handleReactionApi}
                />
            );
        },
        []
    );
    // Reaction API
    const handleReactionApi = (msgId, reactionId) => {
        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user?.api_token}`,
            },
            method: "POST",
            data: {
                message_id: msgId,
                reaction_id: reactionId,
            },
        };
        XHR(`${hostname}/api/v1/updateMessageReaction`, options)
            .then((response) => {
                if (response.data.success) {
                }
            })
            .catch((error) => {
                console.log("ERRRR>", error);
            });
    };
    // DEL API
    const delMsg = (msgId) => {
        const options = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user?.api_token}`,
            },
            method: "POST",
            data: { message_id: msgId },
        };
        XHR(`${hostname}/api/v1/deleteMessage`, options)
            .then((response) => {
                if (response.data) {
                }
            })
            .catch((error) => {
                console.log("ERRRR>", error);
            });
    };
    // EDIT API
    const handleEditMsg = (chatUserId, msgId) => {
        console.log("EDIT HERE");

        const content = inputRefs.current[chatUserId]
            ? inputRefs.current[chatUserId].value
            : "";

        const formdata = new FormData();
        formdata.append("content", content);
        formdata.append("message_id", msgId);
        const options = {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${user?.api_token}`,
            },
            method: "POST",
            data: formdata,
        };
        setLoader((prev) => {
            return {
                ...prev,
                editMsg: chatUserId,
            };
        });
        XHR(`${hostname}/api/v1/editMessage`, options)
            .then((response) => {
                if (response.data) {
                    // Empty input field
                    handleClearInput(chatUserId);
                    setChatData((prev) => {
                        const chatObjIndex = prev.findIndex(
                            (item) => item?.user_id == chatUserId
                        );
                        if (chatObjIndex !== -1) {
                            const temp = [...prev];
                            temp[chatObjIndex] = {
                                ...temp[chatObjIndex],
                                editMsgObj: null,
                            };
                            return temp;
                        }
                        return prev;
                    });
                    //
                }
            })
            .catch((error) => {
                console.log("ERRRR>", error);
            })
            .finally(() => {
                setLoader((prev) => {
                    return {
                        ...prev,
                        editMsg: null,
                    };
                });
            });
    };
    // SEND API
    const sendMessage = (chatUserId, image) => {
        const content = inputRefs.current[chatUserId]
            ? inputRefs.current[chatUserId].value
            : "";
        const msgId = new Date().getTime();

        const data = {
            attachment_file: null,
            offlineImg: image ? image : null,
            content,
            created_at: msgId,
            deleted_at: null,
            msg_id: msgId,
            id: msgId,
            profile_pic: null,
            reactions: [],
            sender_user_id: user?.id,
            username: user?.username,
            status: "pending",
        };

        setChatData((prev) => {
            const temp = [...prev];
            const objIndex = temp.findIndex(
                (item) => item.user_id == chatUserId
            );
            if (objIndex !== -1) {
                temp[objIndex] = {
                    ...temp[objIndex],
                    chat: [data, ...temp[objIndex].chat],
                    // set image to null
                    selectedImg: null,
                };
                return temp;
            }
            return prev;
        });

        // Empty input field
        handleClearInput(chatUserId);
        //

        // setMessage("");
        setTimeout(() => {
            scrollToBottom(chatUserId);
        }, 200);

        setSelectedImg(null);

        const formdata = new FormData();
        formdata.append("msg_id", msgId);
        formdata.append("user_id", user?.id);
        formdata.append("reciever_id", chatUserId);
        formdata.append("content", content);
        formdata.append("conversation_id", 0);
        formdata.append("type", selectedImg === null ? "text" : "image");
        if (image) {
            formdata.append("file", image);
        } else {
            formdata.append("file", null);
        }
        const options = {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${user?.api_token}`,
            },
            method: "POST",
            data: formdata,
        };
        XHR(`${hostname}/api/v1/sendMessage`, options)
            .then((response) => {
                if (response.data) {
                    // console.log("RESP>", response.data);
                }
            })
            .catch((error) => {
                setChatData((prev) => {
                    const temp = [...prev];
                    const objIndex = temp.findIndex(
                        (item) => item.user_id == chatUserId
                    );
                    if (objIndex !== -1) {
                        const tempChat = temp[objIndex].chat.map((item) =>
                            item?.msg_id == msgId
                                ? { ...data, status: "failed" }
                                : item
                        );
                        temp[objIndex] = { ...temp[objIndex], chat: tempChat };
                        return temp;
                    }
                    return prev;
                });
                console.log("Error Config:", JSON.stringify(error?.config));
            });
    };

    // Function to scroll to the bottom
    const scrollToBottom = (chatUserId) => {
        const scrollableDiv = document.getElementById(
            `scrollableDiv${chatUserId}`
        );
        if (scrollableDiv) {
            scrollableDiv.scrollTo({
                top: scrollableDiv.scrollHeight,
                behavior: "smooth",
            });
        }
    };

    // Firebase Listeners
    let newItems = false;
    useEffect(() => {
        // New Message listener
        const newMessageRef = ref(database, "GlobalChatMessageSent");
        onChildAdded(newMessageRef, handleNewMessages);
        // Message count listener
        //  const messageCountRef = ref(database, "GlobalChatMessageCount");
        //  onChildAdded(messageCountRef, handleMessageCount);
        //  // Delete Msg listener
        const messageDelRef = ref(database, "GlobalChatMessageDelete");
        onChildAdded(messageDelRef, handleMessageDel);
        //  // Edit Message listener
        const messageEditRef = ref(database, "GlobalChatMessageEdit");
        onChildAdded(messageEditRef, handleEditListener);
        //  // Rreaction Message listener
        const reactMessageRef = ref(database, "GlobalChatMessageResponse");
        onChildAdded(reactMessageRef, handleReactionListener);

        // handle initial items
        onValue(ref(database), handleInitialItems);
    }, []);

    const handleInitialItems = () => {
        newItems = true;
    };

    // NEW MESSAGES
    const handleNewMessages = (snapshot) => {
        if (!newItems) return;
        if (snapshot.exists()) {
            console.log("MSG>>", snapshot.val());
            const e = snapshot.val();
            setChatData((prev) => {
                const tempChat = [...prev];
                if (e?.message && e.message?.sender_user_id == user?.id) {
                    const chatObjIndex = tempChat.findIndex(
                        (item) => item.user_id == e?.receiver_id
                    );
                    if (chatObjIndex !== -1) {
                        const updatedChat = tempChat[chatObjIndex]?.chat.map(
                            (item) => {
                                if (item?.msg_id == e?.message?.msg_id) {
                                    return {
                                        ...e.message,
                                        deleted_at: null,
                                        reactions: [],
                                        status: "sent",
                                    };
                                } else {
                                    return item;
                                }
                            }
                        );
                        tempChat[chatObjIndex] = {
                            ...tempChat[chatObjIndex],
                            chat: updatedChat,
                            conversation_id: e?.conversation_id,
                        };
                        return tempChat;
                    }
                } else {
                    const chatObjIndex = tempChat.findIndex(
                        (item) => item.user_id == e?.message?.sender_user_id
                    );
                    if (chatObjIndex !== -1) {
                        const newMessage = {
                            ...e.message,
                            deleted_at: null,
                            reactions: [],
                        };
                        tempChat[chatObjIndex] = {
                            ...tempChat[chatObjIndex],
                            chat: [newMessage, ...tempChat[chatObjIndex]?.chat],
                        };
                        return tempChat;
                    }
                }
                return prev;
            });

            if (e?.message && e.message?.sender_user_id == user?.id) {
                setTimeout(() => {
                    scrollToBottom(e?.receiver_id);
                }, 200);
            } else {
                setTimeout(() => {
                    scrollToBottom(e?.message?.sender_user_id);
                }, 200);
            }
        }
    };
    // Listener for Reaction chat messages
    const handleReactionListener = (snapshot) => {
        if (!newItems) return;
        if (snapshot.exists()) {
            const e = snapshot.val();
            console.log("Reaction MSG>", e);
            if (e?.message_id && e?.message_reactions) {
                setChatData((prev) => {
                    const chatObjIndex = prev.findIndex(
                        (el) => el?.conversation_id == e?.conversation_id
                    );
                    if (chatObjIndex !== -1) {
                        const messageIndex = prev?.[
                            chatObjIndex
                        ]?.chat.findIndex((x) => x.id == e.message_id);
                        const tempChat = [...prev?.[chatObjIndex].chat];
                        tempChat[messageIndex] = {
                            ...tempChat[messageIndex],
                            reactions: [...e.message_reactions],
                            deleted_at: null,
                        };
                        const updatedData = [...prev];
                        updatedData[chatObjIndex] = {
                            ...updatedData[chatObjIndex],
                            chat: tempChat,
                        };
                        return updatedData;
                    }
                    return prev;
                });
            } else {
                setChatData((prev) => {
                    const chatObjIndex = prev.findIndex(
                        (el) => el?.conversation_id == e?.conversation_id
                    );
                    if (chatObjIndex !== -1) {
                        const messageIndex = prev?.[
                            chatObjIndex
                        ]?.chat.findIndex((x) => x.id == e.message_id);
                        const tempChat = [...prev?.[chatObjIndex].chat];
                        tempChat[messageIndex] = {
                            ...tempChat[messageIndex],
                            reactions: [],
                            deleted_at: null,
                        };
                        const updatedData = [...prev];
                        updatedData[chatObjIndex] = {
                            ...updatedData[chatObjIndex],
                            chat: tempChat,
                        };
                        return updatedData;
                    }
                    return prev;
                });
            }
        }
    };
    // MESSAGE DELETE
    const handleMessageDel = (snapshot) => {
        if (!newItems) return;
        if (snapshot.exists()) {
            console.log("DEL>>", snapshot.val());
            const deletedMessage = snapshot.val();
            if (deletedMessage?.message_id) {
                setChatData((prev) => {
                    const chatObjIndex = prev.findIndex(
                        (item) =>
                            item.conversation_id ==
                            deletedMessage?.conversation_id
                    );
                    if (chatObjIndex !== -1) {
                        const temp = prev?.[chatObjIndex].chat.map((msg) =>
                            msg.id == deletedMessage.message_id
                                ? {
                                      ...msg,
                                      deleted_at: new Date().toISOString(),
                                      attachment_file: null,
                                  }
                                : msg
                        );
                        const updatedData = [...prev];
                        updatedData[chatObjIndex] = {
                            ...updatedData[chatObjIndex],
                            chat: temp,
                        };
                        return updatedData;
                    }
                    return prev;
                });
            }
        }
    };
    // Edit Message Handler
    const handleEditListener = (snapshot) => {
        if (!newItems) return;
        if (snapshot.exists()) {
            const e = snapshot.val();
            console.log("EDIT MSG>>", e);
            setChatData((prev) => {
                const chatObjIndex = prev.findIndex(
                    (item) => item.conversation_id == e?.conversation_id
                );
                if (chatObjIndex !== -1) {
                    const messageIndex = prev?.[chatObjIndex]?.chat?.findIndex(
                        (x) => x.id == e.message.id
                    );

                    if (messageIndex !== -1) {
                        const tempChat = [...prev?.[chatObjIndex].chat];
                        tempChat[messageIndex] = {
                            ...e.message,
                            reactions: tempChat[messageIndex]?.reactions
                                ? [...tempChat[messageIndex].reactions]
                                : [],
                            deleted_at: null,
                            msg_id: tempChat[messageIndex]?.msg_id,
                        };

                        const updatedData = [...prev];
                        updatedData[chatObjIndex] = {
                            ...updatedData[chatObjIndex],
                            chat: tempChat,
                        };

                        return updatedData;
                    }
                }
                return prev;
            });
        }
    };

    const ChatSection = useCallback(
        ({
            chatData,
            hostname,
            onEndChat,
            handleReactionApi,
            visibleReactions,
            setVisibleReactions,
            delMsg,
            setEditMsg,
            message,
            editMsg,
            loader,
            handleEditMsg,
            sendMessage,
            handleFileChange,
            handleButtonClick,
            handleInputChange,
            inputRefs,
            handleClearInput,
            fileInputRefs,
            setChatData,
        }) => {
            return (
                <div
                    className="d-flex flex-column"
                    style={{
                        width: 320,
                        borderRadius: 10,
                        backgroundColor: "#171717",
                        filter: "drop-shadow(2px 4px 6px black)",
                        // filter:'drop-shadow(grey 2px 2px 2px)',
                        position: "relative",
                        border: "1px solid #3478",
                    }}
                >
                    <div
                        className="d-flex align-items-center justify-content-between"
                        style={{
                            padding: 10,
                        }}
                    >
                        <div className="d-flex gap-2 align-items-center">
                            <img
                                style={{
                                    width: 40,
                                    height: 40,
                                    objectFit: "cover",
                                    borderRadius: 100,
                                }}
                                src={
                                    chatData?.image
                                        ? `${hostname}/${removeStartingSlash(
                                              chatData.image
                                          )}`
                                        : placeholder
                                }
                            />
                            <h5 style={{ color: "#fff", fontSize: 15 }}>
                                {chatData?.name}
                            </h5>
                        </div>
                        <div className="d-flex gap-2 align-items-center">
                            <Minimize2
                                size={20}
                                color="#3478ff"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setChatData((prev) => {
                                        const temp = prev.map((item) =>
                                            item?.user_id == chatData?.user_id
                                                ? { ...item, minimized: true }
                                                : item
                                        );
                                        return temp;
                                    });
                                }}
                            />
                            <X
                                onClick={() => {
                                    setChatData((prev) => {
                                        const temp = prev.filter(
                                            (item) =>
                                                item.user_id !==
                                                chatData?.user_id
                                        );
                                        return temp;
                                    });
                                }}
                                size={24}
                                color="#3478ff"
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    </div>
                    {/* Messages area */}
                    <div
                        className="p-3 message-cont"
                        id={`scrollableDiv${chatData?.user_id}`}
                        style={{
                            height: "calc(65vh - 126px)",
                            overflowY: "auto",
                            // backgroundColor: "#707cae61",
                            backgroundColor: "#3e3f4161",
                            display: "flex",
                            flexDirection: "column-reverse",
                        }}
                    >
                        {loader?.overlay == chatData?.user_id && (
                            <ChatOverayLoader />
                        )}
                        <InfiniteScrollComp
                            dataLength={chatData.chat.length}
                            next={() => onEndChat(chatData?.user_id)}
                            hasMore={
                                chatData.chat_current_page <
                                chatData.chat_last_page
                            }
                            style={{
                                display: "flex",
                                flexDirection: "column-reverse",
                                overflow: "unset",
                            }}
                            inverse={true}
                            loader={
                                <div className="d-flex align-items-center justify-content-center py-3">
                                    <Circles
                                        height="30"
                                        width="30"
                                        color="#347bff"
                                        ariaLabel="circles-loading"
                                        wrapperClass=""
                                        visible={true}
                                    />
                                </div>
                            }
                            scrollableTarget={`scrollableDiv${chatData?.user_id}`}
                        >
                            {generateItems(chatData.chat).map((item, index) => {
                                return (
                                    <RenderItem
                                        key={index}
                                        chat={chatData}
                                        delMsg={delMsg}
                                        handleReactionApi={handleReactionApi}
                                        hostname={hostname}
                                        item={item}
                                        placeholder={placeholder}
                                        removeStartingSlash={
                                            removeStartingSlash
                                        }
                                        setEditMsg={setEditMsg}
                                        setVisibleReactions={
                                            setVisibleReactions
                                        }
                                        user={user}
                                        visibleReactions={visibleReactions}
                                    />
                                );
                            })}
                        </InfiniteScrollComp>
                    </div>

                    {/* Message input */}
                    <div className="d-flex flex-column p-1 mt-auto">
                        {chatData?.editMsgObj !== null && (
                            <div className="d-flex gap-2 align-items-center justify-content-between ">
                                <p style={{ color: "white" }}>
                                    Editing Message
                                </p>
                                <X
                                    onClick={() => setEditMsg(null, chatData)}
                                    size={20}
                                    color="#fff"
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                        )}
                        {chatData?.selectedImg && (
                            <div
                                className="d-flex"
                                style={{
                                    position: "relative",
                                    borderRadius: 10,
                                    border: "1px solid grey",
                                    alignSelf: "flex-start",
                                    padding: 6,
                                }}
                            >
                                <img
                                    src={chatData?.selectedImg}
                                    style={{
                                        height: 55,
                                        width: 55,
                                        objectFit: "cover",
                                        borderRadius: 10,
                                    }}
                                />
                                <X
                                    onClick={() => {
                                        // setSelectedImg(null);
                                        setChatData((prev) => {
                                            const temp = [...prev];
                                            const objIndex = temp.findIndex(
                                                (item) =>
                                                    item?.user_id ==
                                                    chatData.user_id
                                            );
                                            if (objIndex !== -1) {
                                                temp[objIndex] = {
                                                    ...temp[objIndex],
                                                    selectedImg: null,
                                                };
                                                return temp;
                                            }
                                            return prev;
                                        });
                                    }}
                                    size={20}
                                    color="#fff"
                                    style={{
                                        position: "absolute",
                                        top: -7,
                                        right: -7,
                                        cursor: "pointer",
                                    }}
                                />
                            </div>
                        )}
                        <div className="d-flex gap-2 py-2 align-items-center">
                            <input
                                type="file"
                                ref={(el) =>
                                    (fileInputRefs.current[chatData.user_id] =
                                        el)
                                }
                                style={{
                                    display: "none",
                                }}
                                onChange={(e) =>
                                    handleFileChange(e, chatData?.user_id)
                                }
                                accept="image/*"
                            />
                            <Paperclip
                                onClick={() =>
                                    handleButtonClick(chatData?.user_id)
                                }
                                size={26}
                                color="#fff"
                                style={{ cursor: "pointer" }}
                            />
                            <input
                                ref={(el) =>
                                    (inputRefs.current[chatData?.user_id] = el)
                                }
                                defaultValue=""
                                type="text"
                                // value={editMsg.id ? editMsg.msg : message}
                                placeholder="Type a message..."
                                className="form-control"
                                onChange={(e) => {
                                    handleInputChange(
                                        chatData?.user_id,
                                        e.target.value
                                    );
                                }}
                                // onChange={(e) => {
                                //     if (editMsg.id) {
                                //         setEditMsg((prev) => {
                                //             return {
                                //                 ...prev,
                                //                 msg: e.target.value,
                                //             };
                                //         });
                                //     } else {
                                //         setMessage(e.target.value);
                                //     }
                                // }}
                                // onKeyPress={(e) => {
                                //     if (
                                //         e.key !== "Enter" || // Key is not "Enter"
                                //         !chatData?.user_id // Neither conversation_id nor chat_user_id is available
                                //     ) {
                                //         return;
                                //     }

                                //     const isEditMode = Boolean(editMsg?.id);
                                //     const hasValidContent = isEditMode
                                //         ? selectedImg || editMsg?.msg?.trim()
                                //         : selectedImg || message?.trim();
                                //     const isLoading =
                                //         loader.chat || loader.editMsg;

                                //     if (isLoading) return;

                                //     if (isEditMode && hasValidContent) {
                                //         handleEditMsg();
                                //     } else if (!isEditMode && hasValidContent) {
                                //         sendMessage(chatData?.user_id);
                                //     }
                                // }}
                                onKeyPress={(e) => {
                                    if (
                                        e.key !== "Enter" || // Key is not "Enter"
                                        !chatData?.user_id // Neither conversation_id nor chat_user_id is available
                                    ) {
                                        return;
                                    }

                                    if (
                                        chatData?.editMsgObj !== null &&
                                        inputRefs?.current[
                                            chatData.user_id
                                        ]?.value?.trim()
                                    ) {
                                        handleEditMsg(
                                            chatData?.user_id,
                                            chatData?.editMsgObj?.id
                                        );
                                    } else {
                                        if (
                                            chatData?.selectedImg ||
                                            inputRefs?.current[
                                                chatData.user_id
                                            ]?.value?.trim()
                                        ) {
                                            sendMessage(
                                                chatData?.user_id,
                                                chatData?.selectedImg
                                            );
                                        }
                                    }
                                }}
                            />
                            <button
                                className="btn btn-primary d-flex align-items-center justify-content-center"
                                style={{ width: "42px", height: "42px" }}
                                // disabled={
                                //     // Check if any loader is active
                                //     loader.chat ||
                                //     (editMsg.id
                                //         ? !selectedImg && !editMsg.msg.trim() // In edit mode, require either image or message
                                //         : !selectedImg && !message.trim()) // In normal mode, require either image or message
                                // }
                                onClick={() => {
                                    if (
                                        chatData?.editMsgObj !== null &&
                                        inputRefs?.current[
                                            chatData.user_id
                                        ]?.value?.trim()
                                    ) {
                                        console.log("EDIT");
                                        handleEditMsg(
                                            chatData?.user_id,
                                            chatData?.editMsgObj?.id
                                        );
                                    } else {
                                        if (
                                            chatData?.selectedImg ||
                                            inputRefs?.current[
                                                chatData.user_id
                                            ]?.value?.trim()
                                        ) {
                                            sendMessage(
                                                chatData?.user_id,
                                                chatData?.selectedImg
                                            );
                                        }
                                    }
                                }}
                            >
                                {loader?.editMsg == chatData?.user_id ? (
                                    <Circles
                                        height="20"
                                        width="20"
                                        color="#fff"
                                        ariaLabel="circles-loading"
                                        wrapperClass=""
                                        visible={true}
                                    />
                                ) : (
                                    <Send size={20} />
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            );
        },
        []
    );

    const ConvoBubble = useCallback(({ item, setChatData }) => {
        return (
            <div
                onClick={() => {
                    setChatData((prev) => {
                        const temp = prev.map((el) =>
                            el?.user_id == item?.user_id
                                ? { ...el, minimized: false }
                                : el
                        );
                        return temp;
                    });
                }}
                className="chat-head-btn"
            >
                <img
                    style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: 10,
                    }}
                    src={
                        item?.image
                            ? `${hostname}/${removeStartingSlash(item.image)}`
                            : placeholder
                    }
                />
            </div>
        );
    }, []);

    const Tooltip = ({ children, text }) => {
        const [isVisible, setIsVisible] = useState(false);

        return (
            <div
                style={{ position: "relative", display: "inline-block" }}
                onMouseEnter={() => setIsVisible(true)}
                onMouseLeave={() => setIsVisible(false)}
            >
                {children}
                {isVisible && (
                    <div
                        style={{
                            position: "absolute",
                            bottom: "20%",
                            left: -52,
                            transform: "translateX(-50%)",
                            backgroundColor: "black",
                            color: "white",
                            padding: "5px 10px",
                            borderRadius: "4px",
                            fontSize: "14px",
                            whiteSpace: "nowrap",
                            zIndex: 10,
                            fontWeight: "bold",
                            filter: "drop-shadow(grey 1px 1px 1px)",
                        }}
                    >
                        {text}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="chat-head-wrapper gap-2">
            {showConnections && (
                <ConnectionList
                    connections={connections}
                    connectionSearchQuery={connectionSearchQuery}
                    filteredConnections={filteredConnections}
                    loader={loader}
                    debouncedConnectionSetMessage={
                        debouncedConnectionSetMessage
                    }
                    getChat={getChat}
                    setShowChat={setShowChat}
                    setChatData={setChatData}
                    chatData={chatData}
                />
            )}
            {/* {showChat && ( */}
            <div className="d-flex gap-2 align-items-end">
                {chatData.map((item, index) => {
                    return !item?.minimized ? (
                        <ChatSection
                            key={index}
                            chatData={item}
                            setShowChat={setShowChat}
                            hostname={hostname}
                            removeStartingSlash={removeStartingSlash}
                            user={user}
                            placeholder={placeholder}
                            onEndChat={onEndChat}
                            handleReactionApi={handleReactionApi}
                            visibleReactions={visibleReactions}
                            setVisibleReactions={setVisibleReactions}
                            delMsg={delMsg}
                            setEditMsg={(msg) => handleSetEditMsg(msg, item)}
                            editMsg={editMsg}
                            handleEditMsg={handleEditMsg}
                            loader={loader}
                            message={message}
                            selectedImg={selectedImg}
                            sendMessage={sendMessage}
                            fileInputRefs={fileInputRefs}
                            handleFileChange={handleFileChange}
                            handleButtonClick={handleButtonClick}
                            setSelectedImg={setSelectedImg}
                            handleInputChange={handleInputChange}
                            inputRefs={inputRefs}
                            handleClearInput={handleClearInput}
                            setChatData={setChatData}
                        />
                    ) : null;
                })}
            </div>
            <div className="d-flex flex-column align-items-end gap-2">
                {chatData?.map((item, index) => {
                    return item?.minimized ? (
                        <Tooltip key={index} text={item?.name}>
                            <ConvoBubble
                                item={item}
                                setChatData={setChatData}
                            />
                        </Tooltip>
                    ) : null;
                })}

                <NewChat />
            </div>
        </div>
    );
};

export default ChatHeadComp;
