import React, { useState } from "react";
import { removeStartingSlash } from "../../utills/RemoveSlash";
import { Ellipsis, Trash2, UsersRound } from "lucide-react";
import placeholder from "../../assets/placeholder.png";
import moment from "moment";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import { ListItemIcon } from "@mui/material";

const ConversationComp = React.memo(
    ({
        item,
        index,
        handleConversationClick,
        chat,
        setIsHovered,
        isHovered,
        showModal,
    }) => {
        const hostname = process.env.REACT_APP_BASE_URL;

        const [anchorEl, setAnchorEl] = useState(null);
        const isMenuOpen = Boolean(anchorEl);
        // const [isHovered, setIsHovered] = useState(false);

        const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
        const handleMenuClose = (e) => {
            setAnchorEl(null);
            e.stopPropagation();
        };

        const avatarStyle = {
            width: "48px",
            height: "48px",
            fontSize: "1.2rem",
        };

        return (
            <div
                key={item?.conversation_id}
                className="p-3 convo-item"
                style={{
                    backgroundColor:
                        item?.conversation_id == chat?.conversation_id
                            ? "#393737"
                            : "#1b1b1b",
                    borderLeft:
                        item?.conversation_id == chat?.conversation_id
                            ? "3px solid #347bff"
                            : "unset",
                    position: "relative",
                }}
                onClick={(e) => {
                    handleConversationClick(item, index);
                }}
                onMouseEnter={() => setIsHovered(index)}
                onMouseLeave={() => setIsHovered(null)}
            >
                <div
                    className="rounded-circle bg-primary bg-opacity-10 text-primary d-flex align-items-center justify-content-center flex-shrink-0"
                    style={avatarStyle}
                >
                    {item?.type !== "group" ? (
                        <img
                            style={{
                                height: 48,
                                width: 48,
                                borderRadius: 50,
                                objectFit: "cover",
                            }}
                            src={
                                item?.chat_user_profile_pic
                                    ? `${hostname}/${removeStartingSlash(
                                          item?.chat_user_profile_pic
                                      )}`
                                    : placeholder
                            }
                        />
                    ) : item?.conversation_image !== "" &&
                      item?.conversation_image !== null ? (
                        <img
                            style={{
                                height: 48,
                                width: 48,
                                borderRadius: 50,
                                objectFit: "cover",
                            }}
                            source={
                                item?.conversation_image
                                    ? `${hostname}${item?.conversation_image}`
                                    : placeholder
                            }
                        />
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                backgroundColor: "grey",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 50,
                                height: 48,
                                width: 48,
                            }}
                        >
                            <UsersRound size={24} color="#fff" fill="#fff" />
                        </div>
                    )}
                </div>
                <div
                    className="ms-3 flex-grow-1 min-width-0"
                    style={{ maxWidth: 220 }}
                >
                    <div className="d-flex justify-content-between align-items-center">
                        <h6 className="mb-0 text-truncate">
                            {item?.type !== "group"
                                ? item.chat_user_username
                                : item?.conversation_name}
                        </h6>
                        <small style={{ color: "#8f8f8f" }} className=" ms-2">
                            {moment(item?.last_message?.created_at).format(
                                "hh:mm A"
                            )}
                        </small>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <p
                            className="small mb-0 text-truncate"
                            style={{ color: "#8f8f8f" }}
                        >
                            {item?.last_message?.content}
                        </p>
                        {item.read_count > 0 && (
                            <span className="badge bg-primary rounded-pill ms-2">
                                {item.read_count}
                            </span>
                        )}
                    </div>
                </div>
                {isHovered == index && (
                    <div
                        onClick={(e) => {
                            handleMenuClick(e);
                            e.stopPropagation();
                        }}
                        className="d-flex align-items-center justify-content-center p-2"
                        style={{
                            borderRadius: 100,
                            backgroundColor: "#1b1b1b",
                            position: "absolute",
                            right: 20,
                        }}
                    >
                        <Ellipsis size={24} color="#c3c3c3" />
                    </div>
                )}
                {/* Material UI Menu */}
                <Menu
                    sx={{
                        "& .MuiPaper-root": {
                            backgroundColor: "#393737",
                            color: "#fff",
                            borderRadius: "8px",
                            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                            border: "1 px solid #e0e0e0",
                        },
                    }}
                    disableScrollLock={true}
                    anchorEl={anchorEl}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    PaperProps={{
                        elevation: 3,
                        sx: {
                            borderRadius: "8px",
                            minWidth: "160px",
                            marginTop: "4px",
                        },
                    }}
                >
                    <MenuItem
                        onClick={(e) => {
                            showModal(true);
                            handleMenuClose(e);
                            e.stopPropagation();
                        }}
                    >
                        <ListItemIcon>
                            <Trash2 size={20} color="#fff" />
                        </ListItemIcon>
                        <ListItemText>Delete Chat</ListItemText>
                    </MenuItem>
                </Menu>
            </div>
        );
    }
);

export default ConversationComp;
