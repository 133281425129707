import React from "react";
import { Circles } from "react-loader-spinner";

const ChatOverayLoader = () => {
    return (
        <div
            className="position-absolute d-flex align-items-center justify-content-center"
            style={{
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 10,
            }}
        >
            <Circles
                height="40"
                width="40"
                color="#347bff"
                ariaLabel="circles-loading"
                wrapperClass=""
                visible={true}
            />
        </div>
    );
};

export default ChatOverayLoader;
