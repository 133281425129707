import React from "react";
import { Modal } from "react-bootstrap";
import { Circles } from "react-loader-spinner";
import { CircleAlert } from "lucide-react";

const ChatDeleteModal = ({ show, onHide, loader, deleteConversation }) => {
    return (
        <Modal
            size={"lg"}
            centered
            show={show}
            onHide={() => onHide(false)}
            backdrop="static"
        >
            <Modal.Body className="cancel-modal col-md-12">
                <div className="d-flex col-md-12 flex-column align-items-center justify-content-center">
                    <CircleAlert size={50} color="#f8bb86" />
                    <h3
                        style={{
                            color: "#000",
                            fontWeight: "600",
                            marginTop: 20,
                        }}
                    >
                        Delete Chat?
                    </h3>
                    <h6 style={{ color: "#000", marginTop: 10 }}>
                        Once you delete your copy of this conversation, it
                        cannot be undone.
                    </h6>
                </div>
                <div className="d-flex col-md-12 align-items-center justify-content-end gap-3 mt-3">
                    <input
                        style={{
                            borderRadius: 5,
                            backgroundColor: "#f1f1f1",
                            color: "#333333",
                            padding: 10,
                            width: "20%",
                        }}
                        type="button"
                        value={"CANCEL"}
                        onClick={() => onHide(false)}
                    />
                    <button
                        onClick={deleteConversation}
                        style={{
                            borderRadius: 5,
                            backgroundColor: "#FF4C4C",
                            padding: 10,
                            color: "#FFFFFF",
                            paddingInline: 20,
                        }}
                    >
                        {loader ? (
                            <Circles
                                height="20"
                                width="20"
                                color="#fff"
                                ariaLabel="circles-loading"
                                wrapperClass=""
                                visible={true}
                            />
                        ) : (
                            "DELETE"
                        )}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ChatDeleteModal;
