import React, { useState } from "react";
import { Toaster } from "react-hot-toast";
// Assets
import Hamburger from "hamburger-react";
import { useNavigate } from "react-router-dom";

export default function LogoutHeader() {
    const hostname = "redefine.church";
    const [isOpen, setOpen] = useState(false);
    const navigate = useNavigate();

    return (
        <header className="newHeader blueHeader">
            <Toaster position="top-center" />
            <div className="container-fluid">
                <div className="row d-flex align-items-center justify-content-between logout-mian-header ">
                    <div className="d-flex col-md-3 logo-main mainHeader">
                        <a
                            // href="/"
                            onClick={() => navigate("/")}
                            style={{ cursor: "pointer" }}
                        >
                            <img
                                src="https://images.ctfassets.net/1itkm9rji8jb/48jN6KXbjOANg70fZ2oHFE/66ead803c36b23e6752a0a2602e659bc/logo__1_.png"
                                alt="Logo"
                            />
                        </a>
                    </div>
                    {/* hamburger  */}
                    <div className="col-md-3 logo-main header-Logo">
                        <div style={{ width: 60 }} />
                        <a
                            onClick={() => navigate("/")}
                            style={{ cursor: "pointer" }}
                            // href="/"
                        >
                            <img
                                src="https://images.ctfassets.net/1itkm9rji8jb/48jN6KXbjOANg70fZ2oHFE/66ead803c36b23e6752a0a2602e659bc/logo__1_.png"
                                alt="Logo"
                            />
                        </a>
                        <div>
                            <Hamburger toggled={isOpen} toggle={setOpen} />
                            <div
                                className={`mobileMenuDesign ${
                                    isOpen ? "open" : ""
                                }`}
                            >
                                <div className="admin-dashboard-left">
                                    <ul style={{ rowGap: 10 }}>
                                        <li>
                                            <a
                                                // href={`/`}
                                                onClick={() => {
                                                    navigate("/");
                                                    setOpen(false);
                                                }}
                                                style={{
                                                    textDecoration: "none",
                                                    color: "white",
                                                }}
                                            >
                                                <span className="header-heading">
                                                    Home
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                // href={`/about-us`}
                                                onClick={() => {
                                                    navigate("/about-us");
                                                    setOpen(false);
                                                }}
                                                style={{
                                                    textDecoration: "none",
                                                    color: "white",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <span className="header-heading">
                                                    About Us
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                // href={`/serve`}
                                                onClick={() => {
                                                    navigate("/serve");
                                                    setOpen(false);
                                                }}
                                                style={{
                                                    textDecoration: "none",
                                                    color: "white",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <span className="header-heading">
                                                    Serve
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                // href={`/group`}
                                                onClick={() => {
                                                    navigate("/group");
                                                    setOpen(false);
                                                }}
                                                style={{
                                                    textDecoration: "none",
                                                    color: "white",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <span className="header-heading">
                                                    Groups
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                // href={`/kids`}
                                                onClick={() => {
                                                    navigate("/kids");
                                                    setOpen(false);
                                                }}
                                                style={{
                                                    textDecoration: "none",
                                                    color: "white",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <span className="header-heading">
                                                    Kidz
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                onClick={() => {
                                                    navigate("/guest-giving");
                                                    setOpen(false);
                                                }}
                                                // href={`/guest-giving`}
                                                style={{
                                                    textDecoration: "none",
                                                    color: "white",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <span className="header-heading">
                                                    Give
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex col-md-8 align-items-center justify-content-end gap-4 header-shortcut-nav ">
                        <a
                            // href={`/`}
                            onClick={() => navigate("/")}
                            style={{
                                textDecoration: "none",
                                color: "white",
                                cursor: "pointer",
                            }}
                        >
                            <span className="header-heading">Home</span>
                        </a>
                        <a
                            // href={`/about-us`}
                            onClick={() => navigate("/about-us")}
                            style={{
                                textDecoration: "none",
                                color: "white",
                                cursor: "pointer",
                            }}
                        >
                            <span className="header-heading">About Us</span>
                        </a>
                        <a
                            // href={`/serve`}
                            onClick={() => navigate("/serve")}
                            style={{
                                textDecoration: "none",
                                color: "white",
                                cursor: "pointer",
                            }}
                        >
                            <span className="header-heading">Serve</span>
                        </a>
                        <a
                            // href={`/group`}
                            onClick={() => navigate("/group")}
                            style={{
                                textDecoration: "none",
                                color: "white",
                                cursor: "pointer",
                            }}
                        >
                            <span className="header-heading">Groups</span>
                        </a>
                        <a
                            // href={`kids`}
                            onClick={() => navigate("/kids")}
                            style={{
                                textDecoration: "none",
                                color: "white",
                                cursor: "pointer",
                            }}
                        >
                            <span className="header-heading">Kidz</span>
                        </a>
                        <a
                            // href={`/guest-giving`}
                            onClick={() => navigate("/guest-giving")}
                            style={{
                                textDecoration: "none",
                                color: "white",
                                cursor: "pointer",
                            }}
                        >
                            <span className="header-heading">Give</span>
                        </a>
                    </div>
                </div>
            </div>
        </header>
    );
}
