import React, { useEffect, useState, useRef } from "react";
import "../../home/styles.css";
import Assets from "../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ChevronDown } from "lucide-react";
import Draggable from "react-draggable";
import useResizeObserver from "use-resize-observer";
import { getGradient } from "../../../utills/getGradient";
import SpanComp from "../../../components/SpanComp";

const RowWidget = ({ height, width, widgetId, itemStyles, x }) => {
    const [tool1, setTool1] = useState(true);
    const [tool2, setTool2] = useState(true);
    const [tool3, setTool3] = useState(true);

    const editingMode = useSelector((state) => state.bodyStyling.isEdit);
    const universalStyles = useSelector(
        (state) => state.bodyStyling.universalStyles
    );

    const { mainColor } = universalStyles;
    const [default_widget_colors, setDefaultWidgetColors] = useState({});

    useEffect(() => {
        if (universalStyles.default_widget_colors) {
            try {
                const default_widget_colors = JSON.parse(
                    universalStyles.default_widget_colors
                );
                setDefaultWidgetColors(default_widget_colors);
            } catch (error) {
                const default_widget_colors =
                    universalStyles.default_widget_colors;
                setDefaultWidgetColors(default_widget_colors);
            }
        }
    }, [universalStyles]);

    useEffect(() => {
        if (universalStyles.corners && defaultCorners == "true") {
            setBorder(universalStyles.corners);
        }
    }, [universalStyles.corners, universalStyles.font]);

    useEffect(() => {
        if (itemStyles) {
            // setImage(itemStyles.background_image);
            // setImage2(itemStyles.background_image2);
            // setImage3(itemStyles.background_image3);
            // setColor(itemStyles.background_color);
            // for col 1
            if (
                itemStyles.background_color !== "0" &&
                itemStyles.background_color !== null
            ) {
                try {
                    const bgstyle = JSON.parse(itemStyles.background_color);
                    setColor(bgstyle);
                } catch (error) {
                    const bgstyle = itemStyles.background_color;
                    setColor(bgstyle);
                }
            } else {
                if (itemStyles.background_image) {
                    // console.log("IMG HERE>", itemStyles.background_image);
                    setImage(itemStyles.background_image);
                }
            }
            if (itemStyles.accordion1) {
                try {
                    const items = JSON.parse(itemStyles.accordion1);
                    setAccordion1(items);
                } catch (error) {
                    const items = itemStyles.accordion1;
                    setAccordion1(items);
                }
            }
            // for col 2
            if (
                itemStyles.background_color2 !== "0" &&
                itemStyles.background_color2 !== null
            ) {
                try {
                    const bgstyle = JSON.parse(itemStyles.background_color2);
                    setColor2(bgstyle);
                } catch (error) {
                    const bgstyle = itemStyles.background_color2;
                    setColor2(bgstyle);
                }
            } else {
                if (itemStyles.background_image2) {
                    // console.log("IMG HERE>", itemStyles.background_image);
                    setImage2(itemStyles.background_image2);
                }
            }
            if (itemStyles.accordion2) {
                try {
                    const items = JSON.parse(itemStyles.accordion2);
                    setAccordion2(items);
                } catch (error) {
                    const items = itemStyles.accordion2;
                    setAccordion2(items);
                }
            }
            // for col 3
            if (
                itemStyles.background_color3 !== "0" &&
                itemStyles.background_color3 !== null
            ) {
                try {
                    const bgstyle = JSON.parse(itemStyles.background_color3);
                    setColor3(bgstyle);
                } catch (error) {
                    const bgstyle = itemStyles.background_color3;
                    setColor3(bgstyle);
                }
            } else {
                if (itemStyles.background_image3) {
                    setImage3(itemStyles.background_image3);
                }
            }
            if (itemStyles.accordion3) {
                try {
                    const items = JSON.parse(itemStyles.accordion3);
                    setAccordion3(items);
                } catch (error) {
                    const items = itemStyles.accordion3;
                    setAccordion3(items);
                }
            }
            // Accordian coords
            if (itemStyles.accordionCoords1) {
                try {
                    const coords = JSON.parse(itemStyles.accordionCoords1);
                    setAccordionCoords1(coords);
                } catch (error) {
                    const coords = itemStyles.accordionCoords1;
                    setAccordionCoords1(coords);
                }
            }
            if (itemStyles.accordionCoords2) {
                try {
                    const coords = JSON.parse(itemStyles.accordionCoords2);
                    setAccordionCoords2(coords);
                } catch (error) {
                    const coords = itemStyles.accordionCoords2;
                    setAccordionCoords2(coords);
                }
            }
            if (itemStyles.accordionCoords3) {
                try {
                    const coords = JSON.parse(itemStyles.accordionCoords3);
                    setAccordionCoords3(coords);
                } catch (error) {
                    const coords = itemStyles.accordionCoords3;
                    setAccordionCoords3(coords);
                }
            }
            setBorder(itemStyles.border);
            setDefaultCorners(itemStyles.defaultCorners);
            setDefault(itemStyles.is_default);
            setImagePosition(itemStyles.image_position);
            setImagePosition2(itemStyles.image_position2);
            setImagePosition3(itemStyles.image_position3);
            setImgRepeat(itemStyles.image_repeat);
            // texts array
            if (itemStyles.text1) {
                try {
                    const items = JSON.parse(itemStyles.text1);
                    setText1(items);
                } catch (error) {
                    const items = itemStyles.text1;
                    setText1(items);
                    console.error("Error parsing text1:", error);
                }
            }
            if (itemStyles.text2) {
                try {
                    const items = JSON.parse(itemStyles.text2);
                    setText2(items);
                } catch (error) {
                    const items = itemStyles.text2;
                    setText2(items);
                    console.error("Error parsing text1:", error);
                }
            }
            if (itemStyles.text3) {
                try {
                    const items = JSON.parse(itemStyles.text3);
                    setText3(items);
                } catch (error) {
                    const items = itemStyles.text3;
                    setText3(items);
                    console.error("Error parsing text1:", error);
                }
            }
            // heading array
            if (itemStyles.heading1) {
                try {
                    const items = JSON.parse(itemStyles.heading1);
                    setHeading1(items);
                } catch (error) {
                    const items = itemStyles.heading1;
                    setHeading1(items);
                    console.error("Error parsing text1:", error);
                }
            }
            if (itemStyles.heading2) {
                try {
                    const items = JSON.parse(itemStyles.heading2);
                    setHeading2(items);
                } catch (error) {
                    const items = itemStyles.heading2;
                    setHeading2(items);
                    console.error("Error parsing text1:", error);
                }
            }
            if (itemStyles.heading3) {
                try {
                    const items = JSON.parse(itemStyles.heading3);
                    setHeading3(items);
                } catch (error) {
                    const items = itemStyles.heading3;
                    setHeading3(items);
                    console.error("Error parsing text1:", error);
                }
            }
            // paragraph array
            if (itemStyles.paragraph1) {
                try {
                    const items = JSON.parse(itemStyles.paragraph1);
                    setPara1(items);
                } catch (error) {
                    const items = itemStyles.paragraph1;
                    setPara1(items);
                    console.error("Error parsing text1:", error);
                }
            }
            if (itemStyles.paragraph2) {
                try {
                    const items = JSON.parse(itemStyles.paragraph2);
                    setPara2(items);
                } catch (error) {
                    const items = itemStyles.paragraph2;
                    setPara2(items);
                    console.error("Error parsing text1:", error);
                }
            }
            if (itemStyles.paragraph3) {
                try {
                    const items = JSON.parse(itemStyles.paragraph3);
                    setPara3(items);
                } catch (error) {
                    const items = itemStyles.paragraph3;
                    setPara3(items);
                    console.error("Error parsing text1:", error);
                }
            }
            // button array
            if (itemStyles.button1) {
                try {
                    const items = JSON.parse(itemStyles.button1);
                    setButton1(items);
                } catch (error) {
                    const items = itemStyles.button1;
                    setButton1(items);
                    console.error("Error parsing text1:", error);
                }
            }
            if (itemStyles.button2) {
                try {
                    const items = JSON.parse(itemStyles.button2);
                    setButton2(items);
                } catch (error) {
                    const items = itemStyles.button2;
                    setButton2(items);
                    console.error("Error parsing text1:", error);
                }
            }
            if (itemStyles.button3) {
                try {
                    const items = JSON.parse(itemStyles.button3);
                    setButton3(items);
                } catch (error) {
                    const items = itemStyles.button3;
                    setButton3(items);
                    console.error("Error parsing text1:", error);
                }
            }
        }
    }, [itemStyles]);

    const [accordion1, setAccordion1] = useState([]);
    const [accordion2, setAccordion2] = useState([]);
    const [accordion3, setAccordion3] = useState([]);

    const [text1, setText1] = useState([]);
    const [text2, setText2] = useState([]);
    const [text3, setText3] = useState([]);

    const [heading1, setHeading1] = useState([]);
    const [heading2, setHeading2] = useState([]);
    const [heading3, setHeading3] = useState([]);

    const [para1, setPara1] = useState([]);
    const [para2, setPara2] = useState([]);
    const [para3, setPara3] = useState([]);

    const [button1, setButton1] = useState([]);
    const [button2, setButton2] = useState([]);
    const [button3, setButton3] = useState([]);

    const [accordionIndexAndType, setAccordionIndexAndType] = useState({
        index: 0,
        type: "title",
    });
    const [itemIndexTypeCol, setItemIndexTypeCol] = useState({
        index: "",
        type: "",
        col: "",
    });

    const [columnClicked, setColumnClicked] = useState("");

    const [isDefault, setDefault] = useState(0);

    const dispatch = useDispatch();

    const [accordionCoords1, setAccordionCoords1] = useState({ x: 0, y: 25 });
    const [accordionCoords2, setAccordionCoords2] = useState({ x: 0, y: 25 });
    const [accordionCoords3, setAccordionCoords3] = useState({ x: 0, y: 25 });

    const [border, setBorder] = useState("");

    const [image, setImage] = useState("");
    const [image2, setImage2] = useState("");
    const [image3, setImage3] = useState("");

    const [fileBox, showFileBox] = useState(false);

    const [headingIsDashed, setHeadDashed] = useState(false);

    const gradient = {
        points: [
            {
                left: 0,
                red: 0,
                green: 0,
                blue: 0,
                alpha: 1,
            },
            {
                left: 100,
                red: 255,
                green: 0,
                blue: 0,
                alpha: 1,
            },
        ],
        degree: 0,
        type: "linear",
        style: "linear-gradient(0deg,rgba(204, 81, 81, 1) 0%,rgba(255, 0, 0, 1) 100%)",
    };

    const [color, setColor] = useState(false);
    const [color2, setColor2] = useState(false);
    const [color3, setColor3] = useState(false);

    const [defaultCorners, setDefaultCorners] = useState("true");

    // useEffect(() => {
    //   if (itemStyles === undefined) {
    //     setColor(universalStyles.mainColor);
    //   }
    // }, [itemStyles]);

    useEffect(() => {
        if (editingMode.widgtId !== localStorage.getItem("customId")) {
            showFileBox(false);
        }
    }, [editingMode.widgtId]);

    const [imagePosition, setImagePosition] = useState("cover");
    const [imagePosition2, setImagePosition2] = useState("cover");
    const [imagePosition3, setImagePosition3] = useState("cover");

    const [imageRepeat, setImgRepeat] = useState("no-repeat");

    const [isVisible, setVisible] = useState({
        text: true,
        heading: true,
        para: true,
        // button: false,
    });

    const [isShowing, setShow] = useState({
        widget: true,
        button: false,
        text: false,
    });

    //Span height
    function handleSpanChange(event, col, index, type) {
        const span = event.target;
        const width = span.offsetWidth;
        const height = span.offsetHeight;
        if (col === "col1") {
            if (type === "text") {
                setText1((prev) => {
                    return prev.map((item, idx) => {
                        if (idx === index) {
                            return { ...item, height, width };
                        }
                        return item;
                    });
                });
            } else if (type === "heading") {
                setHeading1((prev) => {
                    return prev.map((item, idx) => {
                        if (idx === index) {
                            return { ...item, height, width };
                        }
                        return item;
                    });
                });
            } else if (type === "paragraph") {
                setPara1((prev) => {
                    return prev.map((item, idx) => {
                        if (idx === index) {
                            return { ...item, height, width };
                        }
                        return item;
                    });
                });
            }
        } else if (col === "col2") {
            if (type === "text") {
                setText2((prev) => {
                    return prev.map((item, idx) => {
                        if (idx === index) {
                            return { ...item, height, width };
                        }
                        return item;
                    });
                });
            } else if (type === "heading") {
                setHeading2((prev) => {
                    return prev.map((item, idx) => {
                        if (idx === index) {
                            return { ...item, height, width };
                        }
                        return item;
                    });
                });
            } else if (type === "paragraph") {
                setPara2((prev) => {
                    return prev.map((item, idx) => {
                        if (idx === index) {
                            return { ...item, height, width };
                        }
                        return item;
                    });
                });
            }
        } else if (col === "col3") {
            if (type === "text") {
                setText3((prev) => {
                    return prev.map((item, idx) => {
                        if (idx === index) {
                            return { ...item, height, width };
                        }
                        return item;
                    });
                });
            } else if (type === "heading") {
                setText3((prev) => {
                    return prev.map((item, idx) => {
                        if (idx === index) {
                            return { ...item, height, width };
                        }
                        return item;
                    });
                });
            }
        }
    }
    // Hover Edit

    const mainRef1 = useRef(null);

    const mainDimensions1 = useResizeObserver({ ref: mainRef1 });

    const commonStyles = {
        position: "relative",
        height: "100%",
        padding: 10,
    };

    const backgroundStyles = (
        image,
        default_widget_colors,
        mainColor,
        color,
        imageRepeat,
        imagePosition
    ) => {
        if (image == "0" || image == null || image == false) {
            return {
                background: `${
                    isDefault && default_widget_colors.custom
                        ? default_widget_colors.custom.is_default === "0"
                            ? default_widget_colors.custom.color
                            : default_widget_colors.custom.is_default === "1"
                            ? mainColor
                            : getGradient(color)
                        : getGradient(color)
                }`,
            };
        } else {
            return {
                backgroundImage: `url(${process.env.REACT_APP_BASE_URL}${image})`,
                backgroundPosition: "center",
                backgroundRepeat: imageRepeat,
                backgroundSize: imagePosition,
            };
        }
    };

    const styles = {
        ...commonStyles,
        borderRadius: border === "round" ? 20 : border === "sharp" ? 0 : null,
        ...backgroundStyles(
            image,
            default_widget_colors,
            mainColor,
            color,
            imageRepeat,
            imagePosition
        ),
    };
    const styles2 = {
        ...commonStyles,
        borderRadius: border === "round" ? 20 : border === "sharp" ? 0 : null,
        ...backgroundStyles(
            image2,
            default_widget_colors,
            mainColor,
            color2,
            imageRepeat,
            imagePosition2
        ),
    };
    const styles3 = {
        ...commonStyles,
        borderRadius: border === "round" ? 20 : border === "sharp" ? 0 : null,
        ...backgroundStyles(
            image3,
            default_widget_colors,
            mainColor,
            color3,
            imageRepeat,
            imagePosition3
        ),
    };

    return (
        <div
            className={`d-flex flex-column ${
                editingMode.widgtId == widgetId ? "edit-mode" : ""
            }`}
            style={{
                height: "100%",
                width: "100%",
                borderRadius:
                    border === "round" ? 20 : border === "sharp" ? 0 : null,
                // rowGap: border === "round" ? 20 : 0,
                rowGap:
                    border === "round" && window.innerWidth > 450
                        ? 20 // If border is round AND width > 450px, set gap to 20
                        : border === "round" && window.innerWidth <= 450
                        ? 10 // If border is round AND width <= 450px, set gap to 10
                        : 0,
            }}
        >
            {/* 1st */}
            <div
                // ref={setSecondDroppaleRef1}
                className="firstRow"
                style={styles}
                onClick={() => {
                    setItemIndexTypeCol({ index: "", type: "", col: "" });
                }}
            >
                {/* TEXT */}
                {text1.length > 0 &&
                    text1.map((item, index) => {
                        return (
                            <Draggable
                                disabled
                                key={index}
                                axis="both"
                                handle=".handle"
                                defaultPosition={{ x: item.x, y: item.y }}
                                position={null}
                                // grid={[25, 25]}
                                scale={1}
                                // disabled={isResizing}
                                bounds="parent"
                                onStop={(e, ui) => {
                                    setText1((prev) => {
                                        return prev.map((item, idx) => {
                                            if (idx === index) {
                                                return {
                                                    ...item,
                                                    x: ui.x,
                                                    y: ui.y,
                                                };
                                            }
                                            return item;
                                        });
                                    });
                                }}
                                onDrag={() => {}}
                            >
                                <div
                                    className={`d-flex align-items-end aligned-input`}
                                    style={{
                                        pointerEvents:
                                            widgetId ==
                                            localStorage.getItem("customId")
                                                ? ""
                                                : "none",
                                        ...(headingIsDashed && {
                                            outlineStyle: "dashed",
                                            outlineColor: "whitesmoke",
                                        }),
                                    }}
                                    onFocus={() => {
                                        setVisible({
                                            text: true,
                                            heading: false,
                                            para: true,
                                        });
                                        setShow({
                                            widget: false,
                                            button: false,
                                            text: true,
                                        });
                                    }}
                                >
                                    {editingMode.widgtId == widgetId && (
                                        <div
                                            className="handle d-flex align-items-center justify-content-center"
                                            style={{
                                                position: "absolute",
                                                top: -17,
                                                left: "48%",
                                                cursor: "move",
                                            }}
                                            onMouseOver={() => {
                                                setHeadDashed(true);
                                            }}
                                            onMouseLeave={() => {
                                                setHeadDashed(false);
                                            }}
                                        >
                                            <img
                                                src={Assets.dragIcon}
                                                style={{
                                                    height: 16,
                                                    width: 16,
                                                }}
                                            />
                                        </div>
                                    )}
                                    <SpanComp
                                        item={item}
                                        setText={setText1}
                                        widgetId={widgetId}
                                        index={index}
                                        handleSpanChange={handleSpanChange}
                                        col={"col1"}
                                        default_widget_colors={
                                            default_widget_colors
                                        }
                                        mainDimensions1={mainDimensions1}
                                        isVisible={isVisible}
                                        universalStyles={universalStyles}
                                        type="text"
                                        setItemIndexTypeCol={
                                            setItemIndexTypeCol
                                        }
                                    />
                                </div>
                            </Draggable>
                        );
                    })}

                {/* HEADING */}
                {heading1.length > 0 &&
                    heading1.map((item, index) => {
                        return (
                            <Draggable
                                key={index}
                                disabled
                                axis="both"
                                handle=".handle"
                                defaultPosition={{ x: item.x, y: item.y }}
                                position={null}
                                // grid={[25, 25]}
                                scale={1}
                                // disabled={isResizing}
                                bounds="parent"
                                onStop={(e, ui) => {
                                    setHeading1((prev) => {
                                        return prev.map((item, idx) => {
                                            if (idx === index) {
                                                return {
                                                    ...item,
                                                    x: ui.x,
                                                    y: ui.y,
                                                };
                                            }
                                            return item;
                                        });
                                    });
                                }}
                                onDrag={() => {}}
                            >
                                <div
                                    className={`d-flex align-items-end aligned-input`}
                                    style={{
                                        pointerEvents:
                                            widgetId ==
                                            localStorage.getItem("customId")
                                                ? ""
                                                : "none",
                                        ...(headingIsDashed && {
                                            outlineStyle: "dashed",
                                            outlineColor: "whitesmoke",
                                        }),
                                    }}
                                    onFocus={() => {
                                        setVisible({
                                            text: true,
                                            heading: false,
                                            para: true,
                                        });
                                        setShow({
                                            widget: false,
                                            button: false,
                                            text: true,
                                        });
                                    }}
                                >
                                    {editingMode.widgtId == widgetId && (
                                        <div
                                            className="handle d-flex align-items-center justify-content-center"
                                            style={{
                                                position: "absolute",
                                                top: -17,
                                                left: "48%",
                                                cursor: "move",
                                            }}
                                            onMouseOver={() => {
                                                setHeadDashed(true);
                                            }}
                                            onMouseLeave={() => {
                                                setHeadDashed(false);
                                            }}
                                        >
                                            <img
                                                src={Assets.dragIcon}
                                                style={{
                                                    height: 16,
                                                    width: 16,
                                                }}
                                            />
                                        </div>
                                    )}
                                    <SpanComp
                                        item={item}
                                        setText={setHeading1}
                                        widgetId={widgetId}
                                        index={index}
                                        handleSpanChange={handleSpanChange}
                                        col={"col1"}
                                        default_widget_colors={
                                            default_widget_colors
                                        }
                                        mainDimensions1={mainDimensions1}
                                        isVisible={isVisible}
                                        universalStyles={universalStyles}
                                        type="heading"
                                        setItemIndexTypeCol={
                                            setItemIndexTypeCol
                                        }
                                    />
                                </div>
                            </Draggable>
                        );
                    })}
                {/* PARAGRAPH */}
                {para1.length > 0 &&
                    para1.map((item, index) => {
                        return (
                            <Draggable
                                disabled
                                key={index}
                                axis="both"
                                handle=".handle"
                                defaultPosition={{ x: item.x, y: item.y }}
                                position={null}
                                // grid={[25, 25]}
                                scale={1}
                                // disabled={isResizing}
                                bounds="parent"
                                onStop={(e, ui) => {
                                    setPara1((prev) => {
                                        return prev.map((item, idx) => {
                                            if (idx === index) {
                                                return {
                                                    ...item,
                                                    x: ui.x,
                                                    y: ui.y,
                                                };
                                            }
                                            return item;
                                        });
                                    });
                                }}
                            >
                                <div
                                    className={`d-flex align-items-end aligned-input`}
                                    style={{
                                        pointerEvents:
                                            widgetId ==
                                            localStorage.getItem("customId")
                                                ? ""
                                                : "none",
                                        ...(headingIsDashed && {
                                            outlineStyle: "dashed",
                                            outlineColor: "whitesmoke",
                                        }),
                                    }}
                                    onFocus={() => {
                                        setVisible({
                                            text: true,
                                            heading: false,
                                            para: true,
                                        });
                                        setShow({
                                            widget: false,
                                            button: false,
                                            text: true,
                                        });
                                    }}
                                >
                                    {editingMode.widgtId == widgetId && (
                                        <div
                                            className="handle d-flex align-items-center justify-content-center"
                                            style={{
                                                position: "absolute",
                                                top: -17,
                                                left: "48%",
                                                cursor: "move",
                                            }}
                                            onMouseOver={() => {
                                                setHeadDashed(true);
                                            }}
                                            onMouseLeave={() => {
                                                setHeadDashed(false);
                                            }}
                                        >
                                            <img
                                                src={Assets.dragIcon}
                                                style={{
                                                    height: 16,
                                                    width: 16,
                                                }}
                                            />
                                        </div>
                                    )}
                                    <SpanComp
                                        item={item}
                                        setText={setPara1}
                                        widgetId={widgetId}
                                        index={index}
                                        handleSpanChange={handleSpanChange}
                                        col={"col1"}
                                        default_widget_colors={
                                            default_widget_colors
                                        }
                                        mainDimensions1={mainDimensions1}
                                        isVisible={isVisible}
                                        universalStyles={universalStyles}
                                        type="paragraph"
                                        setItemIndexTypeCol={
                                            setItemIndexTypeCol
                                        }
                                    />
                                </div>
                            </Draggable>
                        );
                    })}
                {/* BUTTON */}
                {button1.length > 0 &&
                    button1.map((item, index) => {
                        return (
                            <Draggable
                                disabled
                                axis="both"
                                handle=".handle"
                                defaultPosition={{ x: item.x, y: item.y }}
                                position={null}
                                scale={1}
                                bounds="parent"
                                onStop={(e, ui) =>
                                    setButton1((prev) => {
                                        return prev.map((item, idx) => {
                                            if (idx === index) {
                                                return {
                                                    ...item,
                                                    x: ui.x,
                                                    y: ui.y,
                                                };
                                            }
                                            return item;
                                        });
                                    })
                                }
                            >
                                <div
                                    className={`aligned-button handle`}
                                    style={{
                                        justifyContent:
                                            height == 8 ? "center" : "flex-end",
                                        pointerEvents:
                                            widgetId ==
                                            localStorage.getItem("customId")
                                                ? ""
                                                : "none",
                                    }}
                                    onFocus={() => {
                                        setShow((prev) => ({
                                            ...prev,
                                            button: true,
                                            widget: false,
                                        }));
                                    }}
                                    onClick={(e) => {
                                        setItemIndexTypeCol({
                                            index,
                                            type: "button",
                                            col: "col1",
                                        });
                                        e.stopPropagation();
                                    }}
                                >
                                    <div className="submit-btn-give">
                                        <input
                                            style={{
                                                // backgroundColor: item.bgColor,
                                                background: getGradient(
                                                    item.bgColor
                                                ),
                                                color: item.txtColor,
                                                fontSize: Number(item.fontSize),
                                                fontFamily:
                                                    item.family === "default"
                                                        ? universalStyles.font
                                                        : item.family
                                                        ? item.family
                                                        : "sans-serif",
                                                fontStyle: item.isItalic
                                                    ? "italic"
                                                    : "normal",
                                                textDecorationLine:
                                                    item.isUnderlined
                                                        ? "underline"
                                                        : "none",
                                                fontWeight: item.isBold
                                                    ? "bold"
                                                    : "normal",
                                            }}
                                            type="submit"
                                            value={
                                                item.text ? item.text : "Send"
                                            }
                                        />
                                    </div>
                                </div>
                            </Draggable>
                        );
                    })}

                {accordion1.length > 0 && (
                    <Draggable
                        disabled
                        axis="both"
                        handle=".handle"
                        defaultPosition={{
                            x: accordionCoords1.x,
                            y: accordionCoords1.y,
                        }}
                        position={null}
                        grid={[25, 25]}
                        scale={1}
                        // disabled={isResizing}
                        bounds="parent"
                        onStop={(e, ui) => {
                            setAccordionCoords1({ x: ui.x, y: ui.y });
                        }}
                        onDrag={() => {}}
                    >
                        <div className={`d-flex flex-column`}>
                            {editingMode.widgtId == widgetId && (
                                <div
                                    className="handle d-flex align-items-center justify-content-center"
                                    style={{
                                        position: "absolute",
                                        top: -17,
                                        left: "48%",
                                        cursor: "move",
                                    }}
                                    onMouseOver={() => {
                                        // setParaDashed(true);
                                    }}
                                    onMouseLeave={() => {
                                        // setParaDashed(false);
                                    }}
                                >
                                    <img
                                        src={Assets.dragIcon}
                                        style={{
                                            height: 16,
                                            width: 16,
                                        }}
                                    />
                                </div>
                            )}
                            {accordion1.map((item, index) => {
                                return (
                                    <Accordion
                                        key={index}
                                        style={{
                                            // header bgColor
                                            backgroundColor: item.titleBgColor,
                                            width: "100%",
                                        }}
                                    >
                                        <AccordionSummary
                                            style={{
                                                width: "100%",
                                                // header font color
                                                color: item.titleTextColor,
                                            }}
                                            expandIcon={
                                                <ChevronDown color="white" />
                                            }
                                            aria-controls="panel1-content"
                                            id={`panel${index}-header`}
                                        >
                                            <span
                                                suppressContentEditableWarning
                                                contentEditable={
                                                    widgetId ==
                                                    localStorage.getItem(
                                                        "customId"
                                                    )
                                                }
                                                data-placeholder="title..."
                                                role="textbox"
                                                // onFocus={() => {
                                                //   setAccordionIndexAndType({ index, type: "title" });
                                                //   setColumnClicked("1st");
                                                //   setItemIndexTypeCol({ index: "", type: "", col: "" });
                                                // }}
                                                onBlur={(e) => {
                                                    setAccordion1((prev) => {
                                                        const updatedItem =
                                                            prev.map(
                                                                (item, i) => {
                                                                    if (
                                                                        index ==
                                                                        i
                                                                    ) {
                                                                        return {
                                                                            ...item,
                                                                            title: e
                                                                                .target
                                                                                .innerText,
                                                                        };
                                                                    } else {
                                                                        return item;
                                                                    }
                                                                }
                                                            );
                                                        return updatedItem;
                                                    });
                                                }}
                                                onClick={(e) => {
                                                    setAccordionIndexAndType({
                                                        index,
                                                        type: "title",
                                                    });
                                                    setColumnClicked("1st");
                                                    setItemIndexTypeCol({
                                                        index: "",
                                                        type: "",
                                                        col: "",
                                                    });
                                                    e.stopPropagation();
                                                }}
                                                style={{
                                                    backgroundColor:
                                                        "transparent",
                                                    width: "100%",
                                                    outline: "unset",
                                                    color: item.titleTextColor,
                                                    fontSize:
                                                        item.titleFontSize,
                                                    fontWeight: item.isTitleBold
                                                        ? "bold"
                                                        : "normal",
                                                    fontStyle:
                                                        item.isTitleItalic
                                                            ? "italic"
                                                            : "normal",
                                                    textDecorationLine:
                                                        item.isTitleUnderlined
                                                            ? "underline"
                                                            : "none",
                                                    fontFamily:
                                                        item.defaultTitleFamily ==
                                                        1
                                                            ? universalStyles.font
                                                            : item.titleFamily
                                                            ? item.titleFamily
                                                            : "sans-serif",
                                                }}
                                            >
                                                {item.title}
                                            </span>
                                        </AccordionSummary>
                                        <AccordionDetails
                                            contentEditable={
                                                widgetId ==
                                                localStorage.getItem("customId")
                                            }
                                            style={{
                                                backgroundColor:
                                                    item.bodyBgColor,
                                                color: item.bodyTextColor,
                                                outline: "unset",
                                                fontSize: item.bodyFontSize,
                                                fontWeight: item.isBodyBold
                                                    ? "bold"
                                                    : "normal",
                                                fontStyle: item.isBodyItalic
                                                    ? "italic"
                                                    : "normal",
                                                textDecorationLine:
                                                    item.isBodyUnderlined
                                                        ? "underline"
                                                        : "none",
                                                fontFamily:
                                                    item.defaultBodyFamily == 1
                                                        ? universalStyles.font
                                                        : item.bodyFamily
                                                        ? item.bodyFamily
                                                        : "sans-serif",
                                            }}
                                            placeholder="detail..."
                                            // onFocus={() => {
                                            //   setAccordionIndexAndType({ index, type: "detail" });
                                            //   setColumnClicked("1st");
                                            //   setItemIndexTypeCol({ index: "", type: "", col: "" });
                                            // }}
                                            onBlur={(e) => {
                                                setAccordion1((prev) => {
                                                    const updatedItem =
                                                        prev.map((item, i) => {
                                                            if (index == i) {
                                                                return {
                                                                    ...item,
                                                                    bodyText:
                                                                        e.target
                                                                            .innerText,
                                                                };
                                                            } else {
                                                                return item;
                                                            }
                                                        });
                                                    return updatedItem;
                                                });
                                            }}
                                            onClick={(e) => {
                                                setAccordionIndexAndType({
                                                    index,
                                                    type: "detail",
                                                });
                                                setColumnClicked("1st");
                                                setItemIndexTypeCol({
                                                    index: "",
                                                    type: "",
                                                    col: "",
                                                });
                                                e.stopPropagation();
                                            }}
                                        >
                                            {item.bodyText}
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            })}
                        </div>
                    </Draggable>
                )}
            </div>
            {/* 2nd */}
            <div
                // ref={setSecondDroppaleRef2}
                className="secondRow"
                style={styles2}
                onClick={() => {
                    setItemIndexTypeCol({ index: "", type: "", col: "" });
                }}
            >
                {tool2 && widgetId == localStorage.getItem("customId") && (
                    <div
                        style={{ zIndex: 1, position: "absolute", top: 0 }}
                        className={`editdeletebtn}`}
                    >
                        <div
                            className="editbtn"
                            onClick={() => {
                                setTool1(true);
                                setTool2(false);
                                setTool3(true);
                            }}
                        >
                            <img
                                src={Assets.editicon}
                                alt="Edit Image"
                                style={{ height: 25 }}
                            />
                        </div>
                    </div>
                )}

                {/* TEXT */}
                {text2.length > 0 &&
                    text2.map((item, index) => {
                        return (
                            <Draggable
                                disabled
                                key={index}
                                axis="both"
                                handle=".handle"
                                defaultPosition={{ x: item.x, y: item.y }}
                                position={null}
                                // grid={[25, 25]}
                                scale={1}
                                // disabled={isResizing}
                                bounds="parent"
                                onStop={(e, ui) => {
                                    setText2((prev) => {
                                        return prev.map((item, idx) => {
                                            if (idx === index) {
                                                return {
                                                    ...item,
                                                    x: ui.x,
                                                    y: ui.y,
                                                };
                                            }
                                            return item;
                                        });
                                    });
                                }}
                                onDrag={() => {}}
                            >
                                <div
                                    className={`d-flex align-items-end aligned-input`}
                                    style={{
                                        pointerEvents:
                                            widgetId ==
                                            localStorage.getItem("customId")
                                                ? ""
                                                : "none",
                                        ...(headingIsDashed && {
                                            outlineStyle: "dashed",
                                            outlineColor: "whitesmoke",
                                        }),
                                    }}
                                    onFocus={() => {
                                        setVisible({
                                            text: true,
                                            heading: false,
                                            para: true,
                                        });
                                        setShow({
                                            widget: false,
                                            button: false,
                                            text: true,
                                        });
                                    }}
                                >
                                    {editingMode.widgtId == widgetId && (
                                        <div
                                            className="handle d-flex align-items-center justify-content-center"
                                            style={{
                                                position: "absolute",
                                                top: -17,
                                                left: "48%",
                                                cursor: "move",
                                            }}
                                            onMouseOver={() => {
                                                setHeadDashed(true);
                                            }}
                                            onMouseLeave={() => {
                                                setHeadDashed(false);
                                            }}
                                        >
                                            <img
                                                src={Assets.dragIcon}
                                                style={{
                                                    height: 16,
                                                    width: 16,
                                                }}
                                            />
                                        </div>
                                    )}
                                    <SpanComp
                                        item={item}
                                        setText={setText2}
                                        widgetId={widgetId}
                                        index={index}
                                        handleSpanChange={handleSpanChange}
                                        col={"col2"}
                                        default_widget_colors={
                                            default_widget_colors
                                        }
                                        mainDimensions1={mainDimensions1}
                                        isVisible={isVisible}
                                        universalStyles={universalStyles}
                                        type={"text"}
                                        setItemIndexTypeCol={
                                            setItemIndexTypeCol
                                        }
                                    />
                                </div>
                            </Draggable>
                        );
                    })}
                {/* HEADING */}
                {heading2.length > 0 &&
                    heading2.map((item, index) => {
                        return (
                            <Draggable
                                disabled
                                key={index}
                                axis="both"
                                handle=".handle"
                                defaultPosition={{ x: item.x, y: item.y }}
                                position={null}
                                // grid={[25, 25]}
                                scale={1}
                                // disabled={isResizing}
                                bounds="parent"
                                onStop={(e, ui) => {
                                    setHeading2((prev) => {
                                        return prev.map((item, idx) => {
                                            if (idx === index) {
                                                return {
                                                    ...item,
                                                    x: ui.x,
                                                    y: ui.y,
                                                };
                                            }
                                            return item;
                                        });
                                    });
                                }}
                                onDrag={() => {}}
                            >
                                <div
                                    className={`d-flex align-items-end aligned-input`}
                                    style={{
                                        pointerEvents:
                                            widgetId ==
                                            localStorage.getItem("customId")
                                                ? ""
                                                : "none",
                                        ...(headingIsDashed && {
                                            outlineStyle: "dashed",
                                            outlineColor: "whitesmoke",
                                        }),
                                    }}
                                    onFocus={() => {
                                        setVisible({
                                            text: true,
                                            heading: false,
                                            para: true,
                                        });
                                        setShow({
                                            widget: false,
                                            button: false,
                                            text: true,
                                        });
                                    }}
                                >
                                    {editingMode.widgtId == widgetId && (
                                        <div
                                            className="handle d-flex align-items-center justify-content-center"
                                            style={{
                                                position: "absolute",
                                                top: -17,
                                                left: "48%",
                                                cursor: "move",
                                            }}
                                            onMouseOver={() => {
                                                setHeadDashed(true);
                                            }}
                                            onMouseLeave={() => {
                                                setHeadDashed(false);
                                            }}
                                        >
                                            <img
                                                src={Assets.dragIcon}
                                                style={{
                                                    height: 16,
                                                    width: 16,
                                                }}
                                            />
                                        </div>
                                    )}
                                    <SpanComp
                                        item={item}
                                        setText={setHeading2}
                                        widgetId={widgetId}
                                        index={index}
                                        handleSpanChange={handleSpanChange}
                                        col={"col2"}
                                        default_widget_colors={
                                            default_widget_colors
                                        }
                                        mainDimensions1={mainDimensions1}
                                        isVisible={isVisible}
                                        universalStyles={universalStyles}
                                        type="heading"
                                        setItemIndexTypeCol={
                                            setItemIndexTypeCol
                                        }
                                    />
                                </div>
                            </Draggable>
                        );
                    })}
                {/* PARAGRAPH */}
                {para2.length > 0 &&
                    para2.map((item, index) => {
                        return (
                            <Draggable
                                disabled
                                key={index}
                                axis="both"
                                handle=".handle"
                                defaultPosition={{ x: item.x, y: item.y }}
                                position={null}
                                // grid={[25, 25]}
                                scale={1}
                                // disabled={isResizing}
                                bounds="parent"
                                onStop={(e, ui) => {
                                    setPara2((prev) => {
                                        return prev.map((item, idx) => {
                                            if (idx === index) {
                                                return {
                                                    ...item,
                                                    x: ui.x,
                                                    y: ui.y,
                                                };
                                            }
                                            return item;
                                        });
                                    });
                                }}
                                onDrag={() => {}}
                            >
                                <div
                                    className={`d-flex align-items-end aligned-input`}
                                    style={{
                                        pointerEvents:
                                            widgetId ==
                                            localStorage.getItem("customId")
                                                ? ""
                                                : "none",
                                        ...(headingIsDashed && {
                                            outlineStyle: "dashed",
                                            outlineColor: "whitesmoke",
                                        }),
                                    }}
                                    onFocus={() => {
                                        setVisible({
                                            text: true,
                                            heading: false,
                                            para: true,
                                        });
                                        setShow({
                                            widget: false,
                                            button: false,
                                            text: true,
                                        });
                                    }}
                                >
                                    {editingMode.widgtId == widgetId && (
                                        <div
                                            className="handle d-flex align-items-center justify-content-center"
                                            style={{
                                                position: "absolute",
                                                top: -17,
                                                left: "48%",
                                                cursor: "move",
                                            }}
                                            onMouseOver={() => {
                                                setHeadDashed(true);
                                            }}
                                            onMouseLeave={() => {
                                                setHeadDashed(false);
                                            }}
                                        >
                                            <img
                                                src={Assets.dragIcon}
                                                style={{
                                                    height: 16,
                                                    width: 16,
                                                }}
                                            />
                                        </div>
                                    )}
                                    <SpanComp
                                        item={item}
                                        setText={setPara2}
                                        widgetId={widgetId}
                                        index={index}
                                        handleSpanChange={handleSpanChange}
                                        col={"col2"}
                                        default_widget_colors={
                                            default_widget_colors
                                        }
                                        mainDimensions1={mainDimensions1}
                                        isVisible={isVisible}
                                        universalStyles={universalStyles}
                                        type="paragraph"
                                        setItemIndexTypeCol={
                                            setItemIndexTypeCol
                                        }
                                    />
                                </div>
                            </Draggable>
                        );
                    })}
                {/* BUTTON */}
                {button2.length > 0 &&
                    button2.map((item, index) => {
                        return (
                            <Draggable
                                disabled
                                axis="both"
                                handle=".handle"
                                defaultPosition={{ x: item.x, y: item.y }}
                                position={null}
                                scale={1}
                                bounds="parent"
                                onStop={(e, ui) =>
                                    setButton2((prev) => {
                                        return prev.map((item, idx) => {
                                            if (idx === index) {
                                                return {
                                                    ...item,
                                                    x: ui.x,
                                                    y: ui.y,
                                                };
                                            }
                                            return item;
                                        });
                                    })
                                }
                            >
                                <div
                                    className={`aligned-button handle`}
                                    style={{
                                        justifyContent:
                                            height == 8 ? "center" : "flex-end",
                                        pointerEvents:
                                            widgetId ==
                                            localStorage.getItem("customId")
                                                ? ""
                                                : "none",
                                    }}
                                    onFocus={() => {
                                        setShow((prev) => ({
                                            ...prev,
                                            button: true,
                                            widget: false,
                                        }));
                                    }}
                                    onClick={(e) => {
                                        setItemIndexTypeCol({
                                            index,
                                            type: "button",
                                            col: "col2",
                                        });
                                        e.stopPropagation();
                                    }}
                                >
                                    <div className="submit-btn-give">
                                        <input
                                            style={{
                                                // backgroundColor: item.bgColor,
                                                background: getGradient(
                                                    item.bgColor
                                                ),
                                                color: item.txtColor,
                                                fontSize: Number(item.fontSize),
                                                fontFamily:
                                                    item.family === "default"
                                                        ? universalStyles.font
                                                        : item.family
                                                        ? item.family
                                                        : "sans-serif",
                                                fontStyle: item.isItalic
                                                    ? "italic"
                                                    : "normal",
                                                textDecorationLine:
                                                    item.isUnderlined
                                                        ? "underline"
                                                        : "none",
                                                fontWeight: item.isBold
                                                    ? "bold"
                                                    : "normal",
                                            }}
                                            type="submit"
                                            value={
                                                item.text ? item.text : "Send"
                                            }
                                        />
                                    </div>
                                </div>
                            </Draggable>
                        );
                    })}
                {accordion2.length > 0 && (
                    <Draggable
                        disabled
                        axis="both"
                        handle=".handle"
                        defaultPosition={{
                            x: accordionCoords2.x,
                            y: accordionCoords2.y,
                        }}
                        position={null}
                        grid={[25, 25]}
                        scale={1}
                        // disabled={isResizing}
                        bounds="parent"
                        onStop={(e, ui) => {
                            setAccordionCoords2({ x: ui.x, y: ui.y });
                        }}
                        onDrag={() => {}}
                    >
                        <div
                            className={`d-flex flex-column`}
                            style={{}}
                            onFocus={() => {}}
                        >
                            {editingMode.widgtId == widgetId && (
                                <div
                                    className="handle d-flex align-items-center justify-content-center"
                                    style={{
                                        position: "absolute",
                                        top: -17,
                                        left: "48%",
                                        cursor: "move",
                                    }}
                                    onMouseOver={() => {
                                        // setParaDashed(true);
                                    }}
                                    onMouseLeave={() => {
                                        // setParaDashed(false);
                                    }}
                                >
                                    <img
                                        src={Assets.dragIcon}
                                        style={{
                                            height: 16,
                                            width: 16,
                                        }}
                                    />
                                </div>
                            )}
                            {accordion2.map((item, index) => {
                                return (
                                    <Accordion
                                        key={index}
                                        style={{
                                            // header bgColor
                                            backgroundColor: item.titleBgColor,
                                            width: "100%",
                                        }}
                                    >
                                        <AccordionSummary
                                            style={{
                                                width: "100%",
                                                // header font color
                                                color: item.titleTextColor,
                                            }}
                                            expandIcon={
                                                <ChevronDown color="white" />
                                            }
                                            aria-controls="panel1-content"
                                            id={`panel${index}-header`}
                                        >
                                            <span
                                                suppressContentEditableWarning
                                                contentEditable={
                                                    widgetId ==
                                                    localStorage.getItem(
                                                        "customId"
                                                    )
                                                }
                                                data-placeholder="title..."
                                                role="textbox"
                                                // onFocus={() => {
                                                //   setAccordionIndexAndType({ index, type: "title" });
                                                //   setColumnClicked("2nd");
                                                //   setItemIndexTypeCol({ index: "", type: "", col: "" });
                                                // }}
                                                onBlur={(e) => {
                                                    setAccordion2((prev) => {
                                                        const updatedItem =
                                                            prev.map(
                                                                (item, i) => {
                                                                    if (
                                                                        index ==
                                                                        i
                                                                    ) {
                                                                        return {
                                                                            ...item,
                                                                            title: e
                                                                                .target
                                                                                .innerText,
                                                                        };
                                                                    } else {
                                                                        return item;
                                                                    }
                                                                }
                                                            );
                                                        return updatedItem;
                                                    });
                                                }}
                                                onClick={(e) => {
                                                    setAccordionIndexAndType({
                                                        index,
                                                        type: "title",
                                                    });
                                                    setColumnClicked("2nd");
                                                    setItemIndexTypeCol({
                                                        index: "",
                                                        type: "",
                                                        col: "",
                                                    });
                                                    e.stopPropagation();
                                                }}
                                                style={{
                                                    backgroundColor:
                                                        "transparent",
                                                    width: "100%",
                                                    outline: "unset",
                                                    fontSize:
                                                        item.titleFontSize,
                                                    fontWeight: item.isTitleBold
                                                        ? "bold"
                                                        : "normal",
                                                    fontStyle:
                                                        item.isTitleItalic
                                                            ? "italic"
                                                            : "normal",
                                                    textDecorationLine:
                                                        item.isTitleUnderlined
                                                            ? "underline"
                                                            : "none",
                                                    fontFamily:
                                                        item.defaultTitleFamily ==
                                                        1
                                                            ? universalStyles.font
                                                            : item.titleFamily
                                                            ? item.titleFamily
                                                            : "sans-serif",
                                                }}
                                            >
                                                {item.title}
                                            </span>
                                        </AccordionSummary>
                                        <AccordionDetails
                                            contentEditable={
                                                widgetId ==
                                                localStorage.getItem("customId")
                                            }
                                            style={{
                                                backgroundColor:
                                                    item.bodyBgColor,
                                                color: item.bodyTextColor,
                                                outline: "unset",
                                                fontSize: item.bodyFontSize,
                                                fontWeight: item.isBodyBold
                                                    ? "bold"
                                                    : "normal",
                                                fontStyle: item.isBodyItalic
                                                    ? "italic"
                                                    : "normal",
                                                textDecorationLine:
                                                    item.isBodyUnderlined
                                                        ? "underline"
                                                        : "none",
                                                fontFamily:
                                                    item.defaultBodyFamily == 1
                                                        ? universalStyles.font
                                                        : item.bodyFamily
                                                        ? item.bodyFamily
                                                        : "sans-serif",
                                            }}
                                            placeholder="detail..."
                                            // onFocus={() => {
                                            //   setAccordionIndexAndType({ index, type: "detail" });
                                            //   setColumnClicked("2nd");
                                            //   setItemIndexTypeCol({ index: "", type: "", col: "" });
                                            // }}
                                            onBlur={(e) => {
                                                setAccordion2((prev) => {
                                                    const updatedItem =
                                                        prev.map((item, i) => {
                                                            if (index == i) {
                                                                return {
                                                                    ...item,
                                                                    bodyText:
                                                                        e.target
                                                                            .innerText,
                                                                };
                                                            } else {
                                                                return item;
                                                            }
                                                        });
                                                    return updatedItem;
                                                });
                                            }}
                                            onClick={(e) => {
                                                setAccordionIndexAndType({
                                                    index,
                                                    type: "detail",
                                                });
                                                setColumnClicked("2nd");
                                                setItemIndexTypeCol({
                                                    index: "",
                                                    type: "",
                                                    col: "",
                                                });
                                                e.stopPropagation();
                                            }}
                                        >
                                            {item.bodyText}
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            })}
                        </div>
                    </Draggable>
                )}
            </div>
            {/* 3rd */}
            <div
                // ref={setSecondDroppaleRef3}
                className="thirdRow"
                style={styles3}
            >
                {/* TEXT */}
                {text3.length > 0 &&
                    text3.map((item, index) => {
                        return (
                            <Draggable
                                disabled
                                key={index}
                                axis="both"
                                handle=".handle"
                                defaultPosition={{ x: item.x, y: item.y }}
                                position={null}
                                // grid={[25, 25]}
                                scale={1}
                                bounds="parent"
                            >
                                <div
                                    className={`d-flex align-items-end aligned-input`}
                                    style={{
                                        ...(headingIsDashed && {
                                            outlineStyle: "dashed",
                                            outlineColor: "whitesmoke",
                                        }),
                                    }}
                                >
                                    <SpanComp
                                        item={item}
                                        setText={setText3}
                                        widgetId={widgetId}
                                        index={index}
                                        handleSpanChange={handleSpanChange}
                                        col={"col3"}
                                        default_widget_colors={
                                            default_widget_colors
                                        }
                                        mainDimensions1={mainDimensions1}
                                        isVisible={isVisible}
                                        universalStyles={universalStyles}
                                        type={"text"}
                                        setItemIndexTypeCol={
                                            setItemIndexTypeCol
                                        }
                                    />
                                </div>
                            </Draggable>
                        );
                    })}
                {/* HEADING */}
                {heading3.length > 0 &&
                    heading3.map((item, index) => {
                        return (
                            <Draggable
                                disabled
                                key={index}
                                axis="both"
                                handle=".handle"
                                defaultPosition={{ x: item.x, y: item.y }}
                                position={null}
                                // grid={[25, 25]}
                                scale={1}
                                bounds="parent"
                            >
                                <div
                                    className={`d-flex align-items-end aligned-input`}
                                    style={{
                                        ...(headingIsDashed && {
                                            outlineStyle: "dashed",
                                            outlineColor: "whitesmoke",
                                        }),
                                    }}
                                >
                                    <SpanComp
                                        item={item}
                                        setText={setHeading3}
                                        widgetId={widgetId}
                                        index={index}
                                        handleSpanChange={handleSpanChange}
                                        col={"col3"}
                                        default_widget_colors={
                                            default_widget_colors
                                        }
                                        mainDimensions1={mainDimensions1}
                                        isVisible={isVisible}
                                        universalStyles={universalStyles}
                                        type="heading"
                                        setItemIndexTypeCol={
                                            setItemIndexTypeCol
                                        }
                                    />
                                </div>
                            </Draggable>
                        );
                    })}
                {/* PARAGRAPH */}
                {para3.length > 0 &&
                    para3.map((item, index) => {
                        return (
                            <Draggable
                                disabled
                                key={index}
                                axis="both"
                                handle=".handle"
                                defaultPosition={{ x: item.x, y: item.y }}
                                position={null}
                                // grid={[25, 25]}
                                scale={1}
                                bounds="parent"
                            >
                                <div
                                    className={`d-flex align-items-end aligned-input`}
                                    style={{
                                        ...(headingIsDashed && {
                                            outlineStyle: "dashed",
                                            outlineColor: "whitesmoke",
                                        }),
                                    }}
                                >
                                    <SpanComp
                                        item={item}
                                        setText={setPara3}
                                        widgetId={widgetId}
                                        index={index}
                                        handleSpanChange={handleSpanChange}
                                        col={"col3"}
                                        default_widget_colors={
                                            default_widget_colors
                                        }
                                        mainDimensions1={mainDimensions1}
                                        isVisible={isVisible}
                                        universalStyles={universalStyles}
                                        type="paragraph"
                                        setItemIndexTypeCol={
                                            setItemIndexTypeCol
                                        }
                                    />
                                </div>
                            </Draggable>
                        );
                    })}
                {button3.length > 0 &&
                    button3.map((item, index) => {
                        return (
                            <Draggable
                                disabled
                                axis="both"
                                handle=".handle"
                                defaultPosition={{ x: item.x, y: item.y }}
                                position={null}
                                scale={1}
                                bounds="parent"
                            >
                                <div
                                    className={`aligned-button handle`}
                                    style={{
                                        justifyContent:
                                            height == 8 ? "center" : "flex-end",
                                    }}
                                >
                                    <div className="submit-btn-give">
                                        <input
                                            style={{
                                                // backgroundColor: item.bgColor,
                                                background: getGradient(
                                                    item.bgColor
                                                ),
                                                color: item.txtColor,
                                                fontSize: Number(item.fontSize),
                                                fontFamily:
                                                    item.family === "default"
                                                        ? universalStyles.font
                                                        : item.family
                                                        ? item.family
                                                        : "sans-serif",
                                                fontStyle: item.isItalic
                                                    ? "italic"
                                                    : "normal",
                                                textDecorationLine:
                                                    item.isUnderlined
                                                        ? "underline"
                                                        : "none",
                                                fontWeight: item.isBold
                                                    ? "bold"
                                                    : "normal",
                                            }}
                                            type="submit"
                                            value={
                                                item.text ? item.text : "Send"
                                            }
                                        />
                                    </div>
                                </div>
                            </Draggable>
                        );
                    })}
                {accordion3.length > 0 && (
                    <Draggable
                        disabled
                        axis="both"
                        handle=".handle"
                        defaultPosition={{
                            x: accordionCoords3.x,
                            y: accordionCoords3.y,
                        }}
                        position={null}
                        grid={[25, 25]}
                        scale={1}
                        style={{ marginTop: 50 }}
                        bounds="parent"
                    >
                        <div className={`d-flex flex-column`}>
                            {accordion3.map((item, index) => {
                                return (
                                    <Accordion
                                        key={index}
                                        style={{
                                            // header bgColor
                                            backgroundColor: item.titleBgColor,
                                            width: "100%",
                                        }}
                                    >
                                        <AccordionSummary
                                            style={{
                                                width: "100%",
                                                // header font color
                                                color: item.titleTextColor,
                                            }}
                                            expandIcon={
                                                <ChevronDown color="white" />
                                            }
                                            aria-controls="panel1-content"
                                            id={`panel${index}-header`}
                                        >
                                            <span
                                                suppressContentEditableWarning
                                                data-placeholder="title..."
                                                role="textbox"
                                                style={{
                                                    backgroundColor:
                                                        "transparent",
                                                    width: "100%",
                                                    outline: "unset",
                                                    fontSize:
                                                        item.titleFontSize,
                                                    fontWeight: item.isTitleBold
                                                        ? "bold"
                                                        : "normal",
                                                    fontStyle:
                                                        item.isTitleItalic
                                                            ? "italic"
                                                            : "normal",
                                                    textDecorationLine:
                                                        item.isTitleUnderlined
                                                            ? "underline"
                                                            : "none",
                                                    fontFamily:
                                                        item.defaultTitleFamily ==
                                                        1
                                                            ? universalStyles.font
                                                            : item.titleFamily
                                                            ? item.titleFamily
                                                            : "sans-serif",
                                                }}
                                            >
                                                {item.title}
                                            </span>
                                        </AccordionSummary>
                                        <AccordionDetails
                                            style={{
                                                backgroundColor:
                                                    item.bodyBgColor,
                                                color: item.bodyTextColor,
                                                outline: "unset",
                                                fontSize: item.bodyFontSize,
                                                fontWeight: item.isBodyBold
                                                    ? "bold"
                                                    : "normal",
                                                fontStyle: item.isBodyItalic
                                                    ? "italic"
                                                    : "normal",
                                                textDecorationLine:
                                                    item.isBodyUnderlined
                                                        ? "underline"
                                                        : "none",
                                                fontFamily:
                                                    item.defaultBodyFamily == 1
                                                        ? universalStyles.font
                                                        : item.bodyFamily
                                                        ? item.bodyFamily
                                                        : "sans-serif",
                                            }}
                                            placeholder="detail..."
                                        >
                                            {item.bodyText}
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            })}
                        </div>
                    </Draggable>
                )}
            </div>
        </div>
    );
};

export default RowWidget;
