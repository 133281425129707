import React, { useState, useEffect, useRef } from "react";
import "../../home/styles.css";
import Assets from "../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import Draggable from "react-draggable";
import useResizeObserver from "use-resize-observer";
import { getGradient } from "../../../utills/getGradient";
import { Rnd } from "react-rnd";

const CustomWidget = ({ height, width, widgetId, itemStyles, x }) => {
    const editingMode = useSelector((state) => state.bodyStyling.isEdit);
    const universalStyles = useSelector(
        (state) => state.bodyStyling.universalStyles
    );

    // const bodyWidth = window.innerWidth;

    const { mainColor, screen_width } = universalStyles || {};
    const [default_widget_colors, setDefaultWidgetColors] = useState({});
    const [imagesArray, setImagesArr] = useState([]);

    const btnRef = useRef(null);
    const mainRef = useRef(null);

    useEffect(() => {
        if (universalStyles.default_widget_colors) {
            try {
                const default_widget_colors = JSON.parse(
                    universalStyles.default_widget_colors
                );
                setDefaultWidgetColors(default_widget_colors);
            } catch (error) {
                const default_widget_colors =
                    universalStyles.default_widget_colors;
                setDefaultWidgetColors(default_widget_colors);
            }
        }
    }, [universalStyles]);

    useEffect(() => {
        if (universalStyles.corners && defaultCorners == "true") {
            setBorder(universalStyles.corners);
        }
        if (universalStyles.font) {
            font.isDefaultFont == 1 &&
                setFont((prevFont) => ({
                    ...prevFont,
                    family: universalStyles.font,
                }));
            headingfont.isDefaultFont == 1 &&
                setHeadingFont((prevFont) => ({
                    ...prevFont,
                    family: universalStyles.font,
                }));
            parafont.isDefaultFont == 1 &&
                setParaFont((prevFont) => ({
                    ...prevFont,
                    family: universalStyles.font,
                }));
            buttonFont.isDefaultFont == 1 &&
                setButtonFont((prevFont) => ({
                    ...prevFont,
                    family: universalStyles.font,
                }));
        }
    }, [universalStyles.corners, universalStyles.font]);
    useEffect(() => {
        if (itemStyles) {
            // setImage(itemStyles.background_image);
            // setColor(itemStyles.background_color);
            if (
                itemStyles.background_color !== "0" &&
                itemStyles.background_color !== null
            ) {
                try {
                    const bgstyle = JSON.parse(itemStyles.background_color);
                    setColor(bgstyle);
                } catch (error) {
                    const bgstyle = itemStyles.background_color;
                    setColor(bgstyle);
                }
            } else {
                if (itemStyles.background_image) {
                    // console.log("IMG HERE>", itemStyles.background_image);
                    setImage(itemStyles.background_image);
                }
            }

            // setBtnBg(itemStyles.button_background_color);
            if (itemStyles.button_background_color) {
                try {
                    const bgstyle = JSON.parse(
                        itemStyles.button_background_color
                    );
                    setBtnBg(bgstyle);
                } catch (error) {
                    const bgstyle = itemStyles.button_background_color;
                    setBtnBg(bgstyle);
                }
            }

            if (itemStyles.images) {
                try {
                    const items = JSON.parse(itemStyles.images);
                    setImagesArr(items);
                } catch (error) {
                    const items = itemStyles.images;
                    setImagesArr(items);
                }
            }

            setBtnTxt(itemStyles.button_text);
            setTxtColor(itemStyles.button_text_color);
            setBtnLink(itemStyles.button_link);
            setBtnAlignment(itemStyles.button_alignmnet);
            setTextAlignment(itemStyles.text_alignmnet);
            setText(itemStyles.title);
            if (itemStyles.title_font_style) {
                try {
                    const fontstyle = JSON.parse(itemStyles.title_font_style);
                    setFont({
                        ...fontstyle,
                        color: itemStyles.title_color,
                        family: itemStyles.title_font,
                        size: itemStyles.title_font_size,
                    });
                    setDefaultText(fontstyle.isDefaultText);
                } catch (error) {
                    const fontstyle = itemStyles.title_font_style;
                    setFont({
                        ...fontstyle,
                        color: itemStyles.title_color,
                        family: itemStyles.title_font,
                        size: itemStyles.title_font_size,
                    });
                    setDefaultText(fontstyle.isDefaultText);
                }
            }
            if (itemStyles.text_direction) {
                setFont((prev) => {
                    return {
                        ...prev,
                        direction: itemStyles.text_direction,
                    };
                });
            }

            setHeadingAlignment(itemStyles.heading_alignmnet);
            setHeadingTxt(itemStyles.heading_title);
            if (itemStyles.heading_title_font_style) {
                try {
                    const headingStyle = JSON.parse(
                        itemStyles.heading_title_font_style
                    );
                    setHeadingFont({
                        ...headingStyle,
                        color: itemStyles.heading_title_color,
                        family: itemStyles.heading_title_font,
                        size: itemStyles.heading_title_font_size,
                    });
                    setDefaultHeading(headingStyle.isDefaultHeading);
                } catch (error) {
                    const headingStyle = itemStyles.heading_title_font_style;
                    setHeadingFont({
                        ...headingStyle,
                        color: itemStyles.heading_title_color,
                        family: itemStyles.heading_title_font,
                        size: itemStyles.heading_title_font_size,
                    });
                    setDefaultHeading(headingStyle.isDefaultHeading);
                    console.error(
                        "Error parsing heading title font style:",
                        error
                    );
                }
            }
            if (itemStyles.heading_direction) {
                setHeadingFont((prev) => {
                    return {
                        ...prev,
                        direction: itemStyles.heading_direction,
                    };
                });
            }

            setParagraphAlignment(itemStyles.paragraph_alignmnet);
            setParagraphTxt(itemStyles.paragraph_title);
            if (itemStyles.paragraph_title_font_style) {
                try {
                    const parastyle = JSON.parse(
                        itemStyles.paragraph_title_font_style
                    );
                    setParaFont({
                        ...parastyle,
                        color: itemStyles.paragraph_title_color,
                        family: itemStyles.paragraph_title_font,
                        size: itemStyles.paragraph_title_font_size,
                    });
                    setDefaultParagraph(parastyle.isDefaultParagraph);
                } catch (error) {
                    const parastyle = itemStyles.paragraph_title_font_style;
                    setParaFont({
                        ...parastyle,
                        color: itemStyles.paragraph_title_color,
                        family: itemStyles.paragraph_title_font,
                        size: itemStyles.paragraph_title_font_size,
                    });
                    setDefaultParagraph(parastyle.isDefaultParagraph);
                }
            }

            if (itemStyles.paragraph_direction) {
                setParaFont((prev) => {
                    return {
                        ...prev,
                        direction: itemStyles.paragraph_direction,
                    };
                });
            }

            if (itemStyles.button_font_style) {
                try {
                    const buttonStyle = JSON.parse(
                        itemStyles.button_font_style
                    );
                    setButtonFont({
                        ...buttonStyle,
                        color: itemStyles.button_text_color,
                        family: itemStyles.button_font,
                        size: itemStyles.button_font_size,
                    });
                    setDefaultButton(buttonStyle.isDefaultButton);
                } catch (error) {
                    const buttonStyle = itemStyles.button_font_style;
                    setButtonFont({
                        ...buttonStyle,
                        color: itemStyles.button_text_color,
                        family: itemStyles.button_font,
                        size: itemStyles.button_font_size,
                    });
                    setDefaultButton(buttonStyle.isDefaultButton);
                    console.error(
                        "Error parsing heading title font style:",
                        error
                    );
                }
            }

            setBorder(itemStyles.border);
            setTextWidth(itemStyles.text_width);
            setTextHeight(itemStyles.text_height);
            setHeadingHeight(itemStyles.heading_height);
            setHeadingWidth(itemStyles.heading_width);
            setParaHeight(itemStyles.paragraph_height);
            setParaWidth(itemStyles.paragraph_width);

            setTextCoords({ x: itemStyles.text_x, y: itemStyles.text_y });
            setHeadingCoords({
                x: itemStyles.heading_x,
                y: itemStyles.heading_y,
            });
            setParagraphCoords({
                x: itemStyles.paragraph_x,
                y: itemStyles.paragraph_y,
            });
            setButtonCoords({
                x: itemStyles.button_x,
                y: itemStyles.button_y,
            });
            if (itemStyles.button_xy_percentage) {
                try {
                    const buttonXY = JSON.parse(
                        itemStyles.button_xy_percentage
                    );
                    setBtnPercentageXY(buttonXY);
                } catch (error) {
                    const buttonXY = itemStyles.button_xy_percentage;
                    setBtnPercentageXY(buttonXY);
                }
            }
            if (itemStyles.text_xy_percentage) {
                try {
                    const textXY = JSON.parse(itemStyles.text_xy_percentage);
                    setTextPercentageXY(textXY);
                } catch (error) {
                    const textXY = itemStyles.text_xy_percentage;
                    setTextPercentageXY(textXY);
                }
            }
            if (itemStyles.heading_xy_percentage) {
                try {
                    const headingXY = JSON.parse(
                        itemStyles.heading_xy_percentage
                    );
                    setHeadPercentageXY(headingXY);
                } catch (error) {
                    const headingXY = itemStyles.heading_xy_percentage;
                    setHeadPercentageXY(headingXY);
                }
            }
            if (itemStyles.paragraph_xy_percentage) {
                try {
                    const paragraphXY = JSON.parse(
                        itemStyles.paragraph_xy_percentage
                    );
                    setParaPercentageXY(paragraphXY);
                } catch (error) {
                    const paragraphXY = itemStyles.paragraph_xy_percentage;
                    setParaPercentageXY(paragraphXY);
                }
            }

            setDefaultCorners(itemStyles.defaultCorners);
            setDefault(itemStyles.is_default);
            setImagePosition(itemStyles.image_position);
            setImgRepeat(itemStyles.image_repeat);
        }
    }, [itemStyles]);

    const [isDefault, setDefault] = useState(0);
    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const updateSize = () => {
            if (mainRef.current) {
                const boundingRect = mainRef.current.getBoundingClientRect();
                setContainerSize({
                    width: boundingRect.width,
                    height: boundingRect.height,
                });
            }

            // if (mainRef.current) {
            //     setContainerSize({
            //         width: mainRef.current.offsetWidth,
            //         height: mainRef.current.offsetHeight,
            //     });
            // }
        };

        const observer = new ResizeObserver(updateSize);

        if (mainRef.current) {
            observer.observe(mainRef.current);
        }

        return () => {
            if (mainRef.current) {
                observer.unobserve(mainRef.current);
            }
        };
    }, [mainRef]);

    const percentToPixel = (percent, dimension, itemWidth, log) => {
        const pixels = (percent * containerSize[dimension]) / 100;

        // if (log) {
        //     console.log("PERCENT>", percent);
        //     console.log("PIXELS>", pixels);
        //     console.log("CONTAINER>", containerSize[dimension]);
        //     console.log("REF WIDTH", mainRef && mainRef.current.offsetWidth);
        // }

        // if (dimension === "width" && itemWidth) {
        //     if (bodyWidth < screen_width) {
        //         const totalItemWidth = itemWidth + pixels;
        //         // console.log("TOTAL WIDTH", totalItemWidth);
        //         // console.log(containerSize["width"]);
        //         if (totalItemWidth > containerSize["width"]) {
        //             return (
        //                 pixels - (totalItemWidth - containerSize["width"]) - 20
        //             );
        //         }
        //     }
        // }

        // if (dimension === "width" && pixels > containerSize[dimension]) {
        //     const pixel = pixels - (172 + 20);
        //     return pixel > 20 ? pixel : 0;
        // }

        // return pixels;

        // Zoom factor because of zoom property applied to main div with 110%
        const zoomFactor = 1.1;
        return pixels / zoomFactor;
    };

    const [isDefaultText, setDefaultText] = useState(0);
    const [isDefaultHeading, setDefaultHeading] = useState(0);
    const [isDefaultParagraph, setDefaultParagraph] = useState(0);
    const [isDefaultButton, setDefaultButton] = useState(0);

    const dispatch = useDispatch();

    const [textCoords, setTextCoords] = useState({ x: 0, y: 0 });
    const [headingCoords, setHeadingCoords] = useState({ x: 0, y: 0 });
    const [paragraphCoords, setParagraphCoords] = useState({ x: 0, y: 0 });
    const [buttonCoords, setButtonCoords] = useState({ x: 0, y: 0 });

    const [btnPercentageXY, setBtnPercentageXY] = useState({ x: 0, y: 0 });
    const [headingPercentageXY, setHeadPercentageXY] = useState({ x: 0, y: 0 });
    const [paragraphPercentageXY, setParaPercentageXY] = useState({
        x: 0,
        y: 0,
    });
    const [textPercentageXY, setTextPercentageXY] = useState({ x: 0, y: 0 });

    const [text, setText] = useState("");
    const [headingTxt, setHeadingTxt] = useState("");
    const [paraTxt, setParagraphTxt] = useState("");
    const [textAlignment, setTextAlignment] = useState("");
    const [headingAlignment, setHeadingAlignment] = useState("");
    const [paragraphAlignment, setParagraphAlignment] = useState("");
    const [buttonAlignment, setBtnAlignment] = useState("");
    const [border, setBorder] = useState("");

    const [textWidth, setTextWidth] = useState(0);
    const [textHeight, setTextHeight] = useState(0);
    const [headingWidth, setHeadingWidth] = useState(0);
    const [headingHeight, setHeadingHeight] = useState(0);
    const [paraWidth, setParaWidth] = useState(0);
    const [paraHeight, setParaHeight] = useState(0);
    const [font, setFont] = useState({
        color: "#ffffff",
        isBold: false,
        isItalic: false,
        isUnderlined: false,
        family: "gilroymedium",
        size: 34,
        isDefaultFont: 1,
        direction: "left",
    });

    const [buttonFont, setButtonFont] = useState({
        color: "#ffffff",
        isBold: false,
        isItalic: false,
        isUnderlined: false,
        family: "gilroymedium",
        size: 18,
        isDefaultFont: 1,
    });

    const [headingfont, setHeadingFont] = useState({
        color: "#ffffff",
        isBold: false,
        isItalic: false,
        isUnderlined: false,
        family: "gilroymedium",
        size: 34,
        isDefaultFont: 1,
        direction: "left",
    });
    const [parafont, setParaFont] = useState({
        color: "#ffffff",
        isBold: false,
        isItalic: false,
        isUnderlined: false,
        family: "gilroymedium",
        size: 34,
        isDefaultFont: 1,
        direction: "left",
    });
    const [btnBg, setBtnBg] = useState("#ffffff");
    const [btnTxt, setBtnTxt] = useState("");
    const [txtColor, setTxtColor] = useState("#000000");
    const [btnlink, setBtnLink] = useState("");
    const [image, setImage] = useState("");

    const gradient = {
        points: [
            {
                left: 0,
                red: 0,
                green: 0,
                blue: 0,
                alpha: 1,
            },
            {
                left: 100,
                red: 255,
                green: 0,
                blue: 0,
                alpha: 1,
            },
        ],
        degree: 0,
        type: "linear",
        style: "linear-gradient(0deg,rgba(204, 81, 81, 1) 0%,rgba(255, 0, 0, 1) 100%)",
    };

    const [color, setColor] = useState(gradient);
    const [defaultCorners, setDefaultCorners] = useState("true");

    useEffect(() => {
        if (itemStyles === undefined) {
            setColor(universalStyles.mainColor);
        }
    }, [itemStyles]);

    const [imagePosition, setImagePosition] = useState("cover");
    const [imageRepeat, setImgRepeat] = useState("no-repeat");

    const [isVisible, setVisible] = useState({
        text: true,
        heading: true,
        para: true,
        // button: false,
    });

    const textRef = useRef(null);
    const headingRef = useRef(null);
    const paraRef = useRef(null);

    const mainDimensions = useResizeObserver({ ref: mainRef });

    const commonStyles = {
        borderRadius:
            border === "round"
                ? 20
                : border === "sharp"
                ? 0
                : universalStyles.corners === "round"
                ? 20
                : 0,
        zoom: window.innerWidth > 468 ? "110%" : "100%",
    };

    const backgroundStyles = (
        image,
        default_widget_colors,
        mainColor,
        color,
        imageRepeat,
        imagePosition
    ) => {
        if (image == "0" || image == null || image == false) {
            return {
                background: `${
                    isDefault && default_widget_colors.custom
                        ? default_widget_colors.custom.is_default === "0"
                            ? default_widget_colors.custom.color
                            : default_widget_colors.custom.is_default === "1"
                            ? mainColor
                            : getGradient(color)
                        : getGradient(color)
                }`,
            };
        } else {
            return {
                backgroundImage: `url(${process.env.REACT_APP_BASE_URL}${image})`,
                backgroundPosition: "center",
                backgroundRepeat: imageRepeat,
                backgroundSize: imagePosition,
            };
        }
    };

    const styles = {
        ...commonStyles,
        ...backgroundStyles(
            image,
            default_widget_colors,
            mainColor,
            color,
            imageRepeat,
            imagePosition
        ),
    };

    const adjustTextPosition = (textWidth, parentWidth, coords, type) => {
        const totalTextRightEdge = coords + textWidth;
        if (totalTextRightEdge > parentWidth) {
            const excessWidth = totalTextRightEdge - parentWidth;
            if (type === "text" && coords > 20) {
                // console.log("textWidth>", totalTextRightEdge);
                // console.log("PARENT>", parentWidth);
                // console.log("EXCESS>", excessWidth);
                // console.log("coords", coords);
                // console.log("NEW>", coords - excessWidth - 15);
                setTextCoords((prevCoords) => ({
                    ...prevCoords,
                    x:
                        prevCoords.x - excessWidth - 15 < 0
                            ? 0
                            : prevCoords.x - excessWidth - 15, // Subtracting an additional 10 pixels as a buffer
                }));
                // Convert pixel coordinates to percentage
                // const parentWidth = mainDimensions?.width || 1; // Fallback to 1 to avoid division by zero
                setTextPercentageXY((prevPercentage) => {
                    const newXPercentage =
                        ((prevPercentage.x - excessWidth - 15) / parentWidth) *
                        100;
                    // console.log("NEW>", newXPercentage);
                    return {
                        ...prevPercentage,
                        x: newXPercentage < 0 ? 0 : newXPercentage,
                    };
                });
            } else if (type == "heading" && coords > 20) {
                setHeadingCoords((prevCoords) => ({
                    ...prevCoords,
                    x:
                        prevCoords.x - excessWidth - 15 < 0
                            ? 0
                            : prevCoords.x - excessWidth - 15, // Subtracting an additional 10 pixels as a buffer
                }));
                // const parentWidth = mainDimensions?.width || 1; // Fallback to 1 to avoid division by zero
                setHeadPercentageXY((prevPercentage) => {
                    const newXPercentage =
                        ((prevPercentage.x - excessWidth - 15) / parentWidth) *
                        100;
                    return {
                        ...prevPercentage,
                        x: newXPercentage < 0 ? 0 : newXPercentage,
                    };
                });
            } else if (type == "paragraph" && coords > 20) {
                setParagraphCoords((prevCoords) => ({
                    ...prevCoords,
                    x:
                        prevCoords.x - excessWidth - 15 < 0
                            ? 0
                            : prevCoords.x - excessWidth - 15, // Subtracting an additional 10 pixels as a buffer
                }));
                // const parentWidth = mainDimensions?.width || 1; // Fallback to 1 to avoid division by zero
                setParaPercentageXY((prevPercentage) => {
                    const newXPercentage =
                        ((prevPercentage.x - excessWidth - 15) / parentWidth) *
                        100;
                    return {
                        ...prevPercentage,
                        x: newXPercentage < 0 ? 0 : newXPercentage,
                    };
                });
            } else if (type == "button" && coords > 20) {
                setButtonCoords((prevCoords) => ({
                    ...prevCoords,
                    x:
                        prevCoords.x - excessWidth - 15 < 0
                            ? 0
                            : prevCoords.x - excessWidth - 15, // Subtracting an additional 10 pixels as a buffer
                }));
                // const parentWidth = mainDimensions?.width || 1; // Fallback to 1 to avoid division by zero
                // setBtnPercentageXY((prevPercentage) => {
                //     const newXPercentage =
                //         ((prevPercentage.x - excessWidth - 15) / parentWidth) *
                //         100;
                //     return {
                //         ...prevPercentage,
                //         x: newXPercentage < 0 ? 0 : newXPercentage,
                //     };
                // });
            }
        }
    };

    const manageCoords = () => {
        // text
        if (itemStyles?.text_width && mainDimensions?.width && textCoords?.x) {
            adjustTextPosition(
                itemStyles.text_width,
                containerSize["width"],
                textCoords?.x,
                "text"
            );
        }
        // heading
        if (
            itemStyles?.heading_width &&
            mainDimensions?.width &&
            headingCoords?.x
        ) {
            adjustTextPosition(
                itemStyles.heading_width,
                containerSize["width"],
                headingCoords.x,
                "heading"
            );
        }
        // paragraph
        if (
            itemStyles?.paragraph_width &&
            mainDimensions?.width &&
            paragraphCoords?.x
        ) {
            adjustTextPosition(
                itemStyles.paragraph_width,
                containerSize["width"],
                paragraphCoords.x,
                "paragraph"
            );
        }
        // button
        if (mainDimensions?.width && buttonCoords?.x && btnRef?.current) {
            const width = btnRef.current.clientWidth;
            adjustTextPosition(
                width,
                containerSize["width"],
                buttonCoords.x,
                "button"
            );
        }
    };

    useEffect(() => {
        manageCoords();
    }, [mainDimensions]);

    return (
        <a
            style={{ textDecoration: "none" }}
            href={itemStyles?.widget_link}
            target="_blank"
        >
            <div
                id="customWidget"
                ref={mainRef}
                className={"customWidget"}
                style={styles}
            >
                {/* TEXT */}
                {textAlignment && (
                    <Draggable
                        disabled
                        axis="both"
                        handle=".handle"
                        // defaultPosition={{ x: textCoords.x, y: textCoords.y }}
                        // position={{
                        //     x: textCoords.x,
                        //     y: textCoords.y,
                        // }}
                        position={{
                            x: percentToPixel(
                                textPercentageXY.x,
                                "width",
                                itemStyles.text_width,
                                true
                            ),
                            y: percentToPixel(textPercentageXY.y, "height"),
                        }}
                        // grid={[25, 25]}
                        scale={1}
                        bounds="parent"
                    >
                        <div className={`d-flex align-items-end aligned-input`}>
                            {textAlignment && (
                                <span
                                    ref={textRef}
                                    id={`${widgetId.toString()}_textSpan`}
                                    // className="handle"
                                    // class="divtext"
                                    role="textbox"
                                    className="text-span"
                                    data-placeholder="text..."
                                    suppressContentEditableWarning
                                    style={{
                                        fontSize: Number(font.size),
                                        color: isDefaultText
                                            ? default_widget_colors.text.color
                                            : font.color,
                                        textDecorationLine: font.isUnderlined
                                            ? "underline"
                                            : "none",
                                        fontWeight: font.isBold
                                            ? "bold"
                                            : "normal",
                                        fontStyle: font.isItalic
                                            ? "italic"
                                            : "normal",
                                        fontFamily:
                                            font.family === "default"
                                                ? universalStyles.font
                                                : font.family
                                                ? font.family
                                                : "sans-serif",
                                        height: itemStyles.text_height,
                                        width: itemStyles.text_width,
                                        resize:
                                            widgetId ==
                                                localStorage.getItem(
                                                    "customId"
                                                ) && !isVisible.text
                                                ? "both"
                                                : "none",
                                        backgroundColor: "transparent",
                                        maxHeight: mainDimensions.height,
                                        maxWidth: mainDimensions.width,
                                        overflow: "auto",
                                        borderColor:
                                            widgetId ==
                                            localStorage.getItem("customId")
                                                ? "#be9494"
                                                : "transparent",
                                        wordWrap: "break-word",
                                        textAlign: font.direction,
                                    }}
                                >
                                    {text}
                                </span>
                            )}
                        </div>
                    </Draggable>
                )}
                {/* HEADING */}
                {headingAlignment && (
                    <Draggable
                        disabled
                        axis="both"
                        handle=".handle"
                        // defaultPosition={{ x: headingCoords.x, y: headingCoords.y }}
                        // position={{ x: headingCoords.x, y: headingCoords.y }}
                        position={{
                            x: percentToPixel(
                                headingPercentageXY.x,
                                "width",
                                itemStyles.heading_width,
                                false
                            ),
                            y: percentToPixel(headingPercentageXY.y, "height"),
                            // y: item.y,
                        }}
                        scale={1}
                        bounds="parent"
                    >
                        <div className={`d-flex align-items-end aligned-input`}>
                            {headingAlignment && (
                                <span
                                    ref={headingRef}
                                    id={`${widgetId.toString()}_headingSpan`}
                                    // className="handle"
                                    // class="divtext"
                                    role="textbox"
                                    data-placeholder="heading..."
                                    className="text-span"
                                    suppressContentEditableWarning
                                    style={{
                                        fontSize: Number(headingfont.size),
                                        // color: headingfont.color ? headingfont.color : "white",
                                        color: isDefaultHeading
                                            ? default_widget_colors.heading
                                                  .color
                                            : headingfont.color,
                                        textDecorationLine:
                                            headingfont.isUnderlined
                                                ? "underline"
                                                : "none",
                                        fontWeight: headingfont.isBold
                                            ? "bold"
                                            : "normal",
                                        fontStyle: headingfont.isItalic
                                            ? "italic"
                                            : "normal",
                                        fontFamily:
                                            headingfont.family === "default"
                                                ? universalStyles.font
                                                : headingfont.family
                                                ? headingfont.family
                                                : "sans-serif",
                                        borderColor:
                                            widgetId ==
                                            localStorage.getItem("customId")
                                                ? "#BE9494"
                                                : "transparent",
                                        resize:
                                            widgetId ==
                                                localStorage.getItem(
                                                    "customId"
                                                ) && !isVisible.heading
                                                ? "both"
                                                : "none",
                                        backgroundColor: "transparent",
                                        overflow: "auto",
                                        maxHeight: mainDimensions.height,
                                        maxWidth: mainDimensions.width,
                                        height: itemStyles.heading_height,
                                        width: itemStyles.heading_width,
                                        wordWrap: "break-word",
                                        textAlign: headingfont.direction,
                                    }}
                                >
                                    {headingTxt}
                                </span>
                            )}
                        </div>
                    </Draggable>
                )}
                {/* PARAGRAPH */}
                {paragraphAlignment && (
                    <Draggable
                        disabled
                        axis="both"
                        handle=".handle"
                        // defaultPosition={{ x: paragraphCoords.x, y: paragraphCoords.y }}
                        // position={{
                        //     x: paragraphCoords.x,
                        //     y: paragraphCoords.y,
                        // }}
                        position={{
                            x: percentToPixel(
                                paragraphPercentageXY.x,
                                "width",
                                itemStyles.paragraph_width,
                                false
                            ),
                            y: percentToPixel(
                                paragraphPercentageXY.y,
                                "height"
                            ),
                            // y: item.y,
                        }}
                        scale={1}
                        bounds="parent"
                    >
                        <div className={`d-flex align-items-end aligned-input`}>
                            {paragraphAlignment && (
                                <span
                                    ref={paraRef}
                                    id={`${widgetId.toString()}_paragraphSpan`}
                                    // className="handle"
                                    // class="divtext"
                                    role="textbox"
                                    className="text-span"
                                    suppressContentEditableWarning
                                    data-placeholder="paragraph..."
                                    style={{
                                        fontSize: Number(parafont.size),
                                        color: isDefaultParagraph
                                            ? default_widget_colors.paragraph
                                                  .color
                                            : parafont.color,
                                        textDecorationLine:
                                            parafont.isUnderlined
                                                ? "underline"
                                                : "none",
                                        fontWeight: parafont.isBold
                                            ? "bold"
                                            : "normal",
                                        fontStyle: parafont.isItalic
                                            ? "italic"
                                            : "normal",
                                        fontFamily:
                                            parafont.family === "default"
                                                ? universalStyles.font
                                                : parafont.family
                                                ? parafont.family
                                                : "sans-serif",
                                        resize:
                                            widgetId ==
                                                localStorage.getItem(
                                                    "customId"
                                                ) && !isVisible.para
                                                ? "both"
                                                : "none",
                                        backgroundColor: "transparent",
                                        overflowY: "auto",
                                        maxHeight: mainDimensions.height,
                                        maxWidth: mainDimensions.width,
                                        height: itemStyles.paragraph_height,
                                        width: itemStyles.paragraph_width,
                                        borderColor:
                                            widgetId ==
                                            localStorage.getItem("customId")
                                                ? "#be9494"
                                                : "transparent",
                                        wordWrap: "break-word",
                                        textAlign: parafont.direction,
                                    }}
                                >
                                    {paraTxt}
                                </span>
                            )}
                        </div>
                    </Draggable>
                )}
                {/*Button*/}
                {buttonAlignment && (
                    <Draggable
                        disabled
                        axis="both"
                        handle=".handle"
                        // defaultPosition={{ x: buttonCoords.x, y: buttonCoords.y }}
                        // position={{ x: buttonCoords.x, y: buttonCoords.y }}
                        position={{
                            x: percentToPixel(
                                btnPercentageXY.x,
                                "width",
                                false,
                                true
                            ),
                            y: percentToPixel(btnPercentageXY.y, "height"),
                        }}
                        scale={1}
                        bounds="parent"
                    >
                        <div
                            className={`aligned-button handle`}
                            style={{
                                justifyContent:
                                    height == 8 ? "center" : "flex-end",
                            }}
                        >
                            <div className="submit-btn-give">
                                <a
                                    href={itemStyles?.button_link}
                                    target="_blank"
                                >
                                    <input
                                        ref={btnRef}
                                        style={{
                                            // backgroundColor: btnBg,
                                            background: getGradient(btnBg),
                                            color: txtColor,
                                            fontSize: Number(buttonFont.size),
                                            fontFamily:
                                                buttonFont.family === "default"
                                                    ? universalStyles.font
                                                    : buttonFont.family
                                                    ? buttonFont.family
                                                    : "sans-serif",
                                            fontStyle: buttonFont.isItalic
                                                ? "italic"
                                                : "normal",
                                            textDecorationLine:
                                                buttonFont.isUnderlined
                                                    ? "underline"
                                                    : "none",
                                            fontWeight: buttonFont.isBold
                                                ? "bold"
                                                : "normal",
                                            border: "1px solid #ccc",
                                        }}
                                        type="submit"
                                        value={btnTxt ? btnTxt : "Send"}
                                    />
                                </a>
                            </div>
                        </div>
                    </Draggable>
                )}
                {/* Images */}
                {imagesArray.length > 0 &&
                    imagesArray.map((item, index) => {
                        return (
                            <Rnd
                                className="rnd"
                                bounds={`.boundClass${widgetId}`}
                                enableResizing={false}
                                disableDragging={true}
                                lockAspectRatio
                                size={{
                                    width: item.width,
                                    height: item.height,
                                }}
                                // position={{ x: item.x, y: item.y }}
                                position={{
                                    x: percentToPixel(
                                        item.x_percentage,
                                        "width"
                                    ),
                                    y: percentToPixel(
                                        item.y_percentage,
                                        "height"
                                    ),
                                    // y: item.y,
                                }}
                            >
                                <img
                                    src={`${process.env.REACT_APP_BASE_URL}${item.image}`}
                                    style={{
                                        height: item.height,
                                        width: item.width,
                                        objectFit: "contain",
                                    }}
                                    alt="Logo Main"
                                />
                            </Rnd>
                        );
                    })}
            </div>
        </a>
    );
};

export default CustomWidget;
