import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    notificationsdata: {},
    // notificationBadge: false,
};

const slice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        setLoading(state, action) {
            state.isLoading = action.payload;
        },
        setNotificationData(state, action) {
            state.notificationsdata = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;
//Actions
export const {
    setLoading,
    setNotificationData,
} = slice.actions;
