import React, { useState, useEffect } from "react";
import "../../home/styles.css";
import Assets from "../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { setLoginModalState } from "../../../store/slices/bodyStyling";

const GroupListWidget = ({
    itemStyles,
    height,
    width,
    widgetId,
    groupData,
}) => {
    const [color, setColor] = useState("");
    const [image, setImage] = useState("");

    const [link, setLink] = useState("");
    const [border, setBorder] = useState("");
    const [defaultCorners, setDefaultCorners] = useState("true");

    const user = useSelector((state) => state.login.userData);
    const dispatch = useDispatch();

    const universalStyles = useSelector(
        (state) => state.bodyStyling.universalStyles
    );

    useEffect(() => {
        if (universalStyles.corners && defaultCorners == "true") {
            setBorder(universalStyles.corners);
        }
        if (universalStyles.font) {
            font.isDefaultFont == 1 &&
                setFont((prevFont) => ({
                    ...prevFont,
                    family: universalStyles.font,
                }));
            subfont.isDefaultFont == 1 &&
                setSubFont((prevFont) => ({
                    ...prevFont,
                    family: universalStyles.font,
                }));
            dateFont.isDefaultFont == 1 &&
                setDateFont((prevFont) => ({
                    ...prevFont,
                    family: universalStyles.font,
                }));
        }
    }, [universalStyles.corners, universalStyles.font]);

    useEffect(() => {
        if (itemStyles) {
            // setImage(itemStyles.background_image);
            // setColor(itemStyles.background_color);
            if (
                itemStyles.background_color !== "0" &&
                itemStyles.background_color !== null
            ) {
                try {
                    const bgstyle = JSON.parse(itemStyles.background_color);
                    setColor(bgstyle);
                } catch (error) {
                    const bgstyle = itemStyles.background_color;
                    setColor(bgstyle);
                }
            } else {
                if (itemStyles.background_image) {
                    // console.log("IMG HERE>", itemStyles.background_image);
                    setImage(itemStyles.background_image);
                }
            }
            if (itemStyles.title_font_style) {
                try {
                    const fontstyle = JSON.parse(itemStyles.title_font_style);
                    setFont({
                        ...fontstyle,
                        color: itemStyles.title_color,
                        family: itemStyles.title_font,
                        size: itemStyles.title_font_size,
                    });
                } catch (error) {
                    const fontstyle = itemStyles.title_font_style;
                    setFont({
                        ...fontstyle,
                        color: itemStyles.title_color,
                        family: itemStyles.title_font,
                        size: itemStyles.title_font_size,
                    });
                }
            }
            if (itemStyles.date_font_style) {
                try {
                    const dateStyle = JSON.parse(itemStyles.date_font_style);
                    setDateFont({
                        ...dateStyle,
                        color: itemStyles.date_font_color,
                        family: itemStyles.date_font,
                        size: itemStyles.date_font_size,
                    });
                } catch (error) {
                    const dateStyle = itemStyles.date_font_style;
                    setDateFont({
                        ...dateStyle,
                        color: itemStyles.date_font_color,
                        family: itemStyles.date_font,
                        size: itemStyles.date_font_size,
                    });
                }
            }
            if (itemStyles.description_font_style) {
                try {
                    const descriptionStyle = JSON.parse(
                        itemStyles.description_font_style
                    );
                    setSubFont({
                        ...descriptionStyle,
                        color: itemStyles.description_color,
                        family: itemStyles.description_font,
                        size: itemStyles.description_font_size,
                    });
                } catch (error) {
                    const descriptionStyle = itemStyles.description_font_style;
                    setSubFont({
                        ...descriptionStyle,
                        color: itemStyles.description_color,
                        family: itemStyles.description_font,
                        size: itemStyles.description_font_size,
                    });
                }
            }
            setLink(itemStyles.widget_link);
            setBorder(itemStyles.border);
            setDefaultCorners(itemStyles.defaultCorners);
            setDefault(itemStyles.is_default);
        }
    }, [itemStyles]);

    const [isDefault, setDefault] = useState(0);
    const [font, setFont] = useState({
        color: "#ffffff",
        isBold: false,
        isItalic: false,
        isUnderlined: false,
        family: "frontage",
        size: 30,
        isDefaultFont: 1,
    });
    const [subfont, setSubFont] = useState({
        color: "#ffffff",
        isBold: false,
        isItalic: false,
        isUnderlined: false,
        family: "frontage",
        size: 10,
        isDefaultFont: 1,
    });
    const [dateFont, setDateFont] = useState({
        color: "#ffffff",
        isBold: false,
        isItalic: false,
        isUnderlined: false,
        family: "frontage",
        size: 10,
        isDefaultFont: 1,
    });

    return (
        <div
            className={`groupList d-flex align-items-center`}
            style={{
                padding: 10,
                borderRadius:
                    border === "round" ? 20 : border === "sharp" ? 0 : null,
                backgroundImage: `url(${process.env.REACT_APP_BASE_URL}${groupData?.group_banner})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
            }}
        >
            <div className="d-flex flex-column">
                <h1 className="group-list-title" style={{ color: "#fff" }}>
                    {groupData?.title}
                </h1>
                <h4 className="group-list-desp" style={{ color: "#fff" }}>
                    {groupData?.description}
                </h4>
                <div
                    style={{
                        // position: "absolute",
                        // right: 30,
                        // bottom: 30,
                        border: "1px solid #fff",
                        alignSelf:'flex-end'
                    }}
                >
                    <button
                        onClick={() =>
                            !user?.id && dispatch(setLoginModalState(true))
                        }
                        style={{
                            backgroundColor: "transparent",
                            color: "#fff",
                            paddingBlock: 4,
                        }}
                    >
                        {groupData?.status}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default GroupListWidget;
